import css from "./FeedListScreen.module.sass"

import React, { useState, useContext, useEffect } from "react"
import { useQuery } from "react-apollo"
import PropTypes from "prop-types"
import { useLocalStorage } from 'react-use'

import { GET_FEED_LIST } from "../constants/queries"
import Layout from "../components/Layout"
import Loader from "../components/Loader"
import Alert from "../components/Alert"
import { formatGraphQLError } from "../helpers/errors"
import FeedItem from "../components/FeedList/FeedItem"
import Button from "../components/Button"
import Filter from "../components/Feed/Filter"
import Text from '../components/Text'
import Ionicon from '../components/Ionicon'
import Modal from '../components/Modal'
import Search from '../components/Search'

import DevicesContext from '../components/DevicesContext'
import InfiniteScroll from 'react-infinite-scroll-component'
import Tooltip from "../components/Tooltip";

const FeedListScreen = () => {
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [total, setTotal] = useState(0)
  const [companies, setCompanies] = useState([])
  const [applicationTrack, setApplicationTrack] = useState(JSON.parse(localStorage.getItem('feedApplicationTrack')) || [])
  const [applicationTrackData, setApplicationTrackData] = useState(JSON.parse(localStorage.getItem('feedApplicationTrack')) || [])
  const [stage, setStage] = useState(JSON.parse(localStorage.getItem('feedStage')) || null)
  const [stageData, setStageData] = useState(JSON.parse(localStorage.getItem('feedStage')) || null)
  const [industriesList, setIndustriesList] = useState(JSON.parse(localStorage.getItem('feedIndustries')) || [])
  const [industries, setIndustries] = useState(JSON.parse(localStorage.getItem('feedIndustries')) || [])

  const [searchTerm, setSearchTerm, removeSearchTerm] = useLocalStorage('feed-search-term', '')
  const [cursor, setCursor] = useState(null)
  const { mobile } = useContext(DevicesContext)

  const { data: savedData, loading, error, networkStatus, refetch } = useQuery(GET_FEED_LIST, {
    variables: {
      first: 20,
      after: cursor,
      tracks: applicationTrackData.map(i => i.id),
      industries: industriesList.map(i => i.id),
      stages: stageData ? stageData.map(i => i.id) : [],
      q: searchTerm,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (responseData) => {
      if (responseData?.getFeed?.edges) {
        setCompanies(companies.concat(responseData.getFeed.edges.map(({ node }) => node)))
      }
      if (responseData?.getFeed?.total) {
        setTotal(responseData.getFeed.total)
      }
    }
  })

  useEffect(() => {
    localStorage.setItem('feedApplicationTrack', JSON.stringify(applicationTrack))
  }, [applicationTrack])

  useEffect(() => {
    localStorage.setItem('feedIndustries', JSON.stringify(industries))
  }, [industries])

  useEffect(() => {
    localStorage.setItem('feedStage', JSON.stringify(stage))
  }, [stage])

  const fetchMoreListItems = () => {
    setCursor(savedData?.getFeed?.pageInfo?.endCursor)
  }

  const handleSaveFilter = () => {
    setApplicationTrackData(applicationTrack)
    setIndustriesList(industries)
    setStageData(stage)
    setOpenFilterModal(false)
    clearForQuery()
  }

  const handleCancelFilter = () => {
    setApplicationTrack(applicationTrack.slice(0, applicationTrackData.length))
    setIndustries(industries.slice(0, industriesList.length))
    setStage(stageData)
    setOpenFilterModal(false)
  }

  const handleClearFilter = () => {
    setApplicationTrackData([])
    setApplicationTrack([])
    setIndustriesList([])
    setStageData(null)
    setIndustries([])
    setStage(null)
    clearForQuery()
  }

  const clearForQuery = () => {
    setCompanies([])
    setTotal(0)
    setCursor(null)
    refetch()
  }

  const handleSearchTermChange = (value) => {
    setSearchTerm(value)
    clearForQuery()
  }
  const handleSearchTermClear = () => {
    removeSearchTerm()
    clearForQuery()
  }


  const renderFilter = () => {
    return (
      <InfiniteScroll
        dataLength={companies.length}
        next={fetchMoreListItems}
        hasMore={savedData?.getFeed?.pageInfo?.hasNextPage}
      >
        <div className={css.paginationContainer}>
          <div>
            <Text variant='large' offset='half-right'>
              Total submissions {total}
            </Text>
            {
              (applicationTrack.length || industries.length || stage?.length) ?
                <Button
                  variant='filterAdded'
                  icon={
                    <Ionicon
                      name='filter_add_blue'
                      strokeWidth='32'
                      size={!mobile ? '20' : '12'}
                    />
                  }
                  onClick={() => { setOpenFilterModal(true) }}
                >
                  &nbsp;Filter Added
                </Button>
                :
                <Button
                  variant='pagination'
                  icon={
                    <Ionicon
                      name='filter_add'
                      strokeWidth='32'
                      size={!mobile ? '20' : '12'}
                    />
                  }
                  onClick={() => { setOpenFilterModal(true) }}
                >
                  &nbsp;Add Filter
                </Button>
            }
            <Button
              variant='pagination'
              icon={<Ionicon name='refresh' size='20' />}
              onClick={() => { handleClearFilter() }}
            >
              &nbsp;Refresh
            </Button>
          </div>
          <div>
            <Search
              term={searchTerm}
              onChange={handleSearchTermChange}
              onClear={handleSearchTermClear}
            />
            &nbsp;
            <Tooltip
              hoverable
              content="Note: The search tool does not search ratings, comments or notes."
            >
              <Ionicon
                name='information_circle_outline'
                size='24'
              />
            </Tooltip>
          </div>
        </div>
      </InfiniteScroll>
    )
  }

  return (<>
    {error && <Layout variant='dashboard'>
      <Alert variant='error'>{formatGraphQLError(error)}</Alert>
    </Layout>
    }
    <Layout background='white' noFooter>
      <>
        {renderFilter()}

        <table className={css.dataTable}>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Description</th>
              <th>Location</th>
              <th>Industry</th>
              <th>Customer Category</th>
              <th className={css.stage}>Stage</th>
              <th>Track</th>
              <th>Pitch</th>
              <th>Rated</th>
              <th>Total Score</th>
              <th>Comments</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {companies &&
              companies.map((company, index) => {
                return <FeedItem key={index} index={index} company={company} />
              })
            }
          </tbody>
        </table>

        {(loading || networkStatus === 4) &&
          <div className={css.loading}>
            <Loader variant='centered' />
          </div>
        }
      </>
    </Layout>

    <Modal
      variant="filter"
      modalOpened={openFilterModal}
      noButtons
      fullFooter
      title={<>

        <Text tag="h1" variant="h2" color="white">Filters</Text>
        <Button variant="icon" className={css.btnClose} onClick={() => handleCancelFilter()}>
          <Ionicon name="close" size="32" color="white" />
        </Button>
      </>
      }
      content={
        <Filter
          track={applicationTrack}
          setTrack={setApplicationTrack}
          industries={industries}
          setIndustries={setIndustries}
          stage={stage}
          setStage={setStage}
          onSaveFilter={handleSaveFilter}
          onCancelFilter={handleCancelFilter}
          onClearFilter={handleClearFilter}
        />
      }
    />
  </>)
}

FeedListScreen.propTypes = {
  location: PropTypes.object,
}

export default FeedListScreen