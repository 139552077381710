import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

// import Ionicon from '../Ionicon'
import Dropdown from './Dropdown'

const GET_TRACKS = gql`
  query getTracks {
    tracks: getTracks {
      id
      name
    }
  }
`

const TrackDropdown = ({ data, ...dropdownProps }) => {
  const { options, groups } = useMemo(() => {
    const options = data.tracks ? data.tracks.map(x => ({ value: x, label: x.name })) : []
    const groups = [
      { label: "The Athlete Experience", options: [1, 2, 3, 9] },
      { label: "The Event Experience", options: [4, 5, 6, 7] },
      { label: "The Padel Experience", options: [8, 10, 11] }
    ]
    return { options, groups }
  }, [data.tracks])

  return (
    <Dropdown
      options={options}
      groups={groups}
      valueIdKey='id'
      // icon={<Ionicon name='basketBall' color='deepBlue' />}
      renderLabel={value => value.name}
      {...dropdownProps}
    />
  )
}

TrackDropdown.propTypes = {
  data: PropTypes.object
}

export default graphql(GET_TRACKS)(TrackDropdown)
