import { useRef, useEffect, useMemo } from 'react'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'

import { gtmTrack } from './tracking'
import { durationToSeconds } from './videos'

import { VIDEO_FINISHED_STATUS, TOPICS } from '../constants/videos'
import { GTM_PITCHTAPE_VIEWED, GTM_PITCHTAPE_VIEWING_STOP } from '../constants/gtm'
import { GET_USER_COMPANY } from '../constants/queries'
import {
  COMPANY_VIDEO_SEGMENT_FRAGMENT,
  ERRORS_FOR_FIELDS_FRAGMENT,
  COMPANY_TELEPROMPT_FRAGMENT
} from '../constants/fragments'


export function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export function useMountedRef() {
  const mountedRef = useRef(false)

  useEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  })

  return mountedRef
}

export function usePitchtapeTracking(company) {
  useEffect(() => {
    if (!company) {
      return
    }

    gtmTrack(GTM_PITCHTAPE_VIEWED, {
      pitchtape: {
        id: company.id
      }
    })

    const loadedDate = new Date()

    return () => {
      gtmTrack(GTM_PITCHTAPE_VIEWING_STOP, {
        pitchtape: {
          id: company.id,
          viewDuration: Math.floor((new Date() - loadedDate) / 1000)
        }
      })
    }
  }, [company])
}

const UPLOAD_COMPANY_VIDEO_SEGMENT = gql`
  mutation uploadCompanyVideoSegment ($binary: Upload!, $subject: String!,$duration: String!) {
    uploadCompanyVideoSegment(input: { binary: $binary, subject: $subject, duration:$duration }) {
      obj {
        ...companyVideoSegment
      }
      errors {
        ...errorsForFields
      }
    }
  }
  ${COMPANY_VIDEO_SEGMENT_FRAGMENT}
  ${ERRORS_FOR_FIELDS_FRAGMENT}
`

export function useUploadCompanyVideoSegment(options) {
  return useMutation(UPLOAD_COMPANY_VIDEO_SEGMENT, {
    update: (cache, { data: { uploadCompanyVideoSegment: { obj, errors } } }) => {
      if (errors) {
        return
      }

      const { company } = cache.readQuery({ query: GET_USER_COMPANY })
      const nextVideoSegments = company.videoSegments.filter(v => v.subject !== obj.subject).concat(obj)

      cache.writeQuery({
        query: GET_USER_COMPANY,
        data: {
          company: {
            ...company,
            videoSegments: nextVideoSegments,
            concatenatedVideo: company.concatenatedVideo && {
              ...company.concatenatedVideo,
              videoIsUpToDate: false
            }
          }
        }
      })
    },
    ...options
  })
}

const SAVE_COMPANY_TELEPROMPT = gql`
  mutation saveCompanyTeleprompt ($input: SaveCompanyTelepromptMutationInput!) {
    saveCompanyTeleprompt(input: $input) {
      obj {
        ...companyTeleprompt
      }
      errors {
        ...errorsForFields
      }
    }
  }
  ${COMPANY_TELEPROMPT_FRAGMENT}
  ${ERRORS_FOR_FIELDS_FRAGMENT}
`

export function useSaveCompanyTelepromptMutation(options) {
  return useMutation(SAVE_COMPANY_TELEPROMPT, {
    update: (cache, { data: { saveCompanyTeleprompt: { obj, errors } } }) => {
      if (errors) {
        return
      }

      const { company } = cache.readQuery({ query: GET_USER_COMPANY })
      const nextTeleprompts = company.teleprompts.filter(p => p.subject !== obj.subject).concat(obj)

      cache.writeQuery({
        query: GET_USER_COMPANY,
        data: {
          company: {
            ...company,
            teleprompts: nextTeleprompts
          }
        }
      })
    },
    ...options
  })
}

export function useSegments(company) {
  return useMemo(() => {
    if (!company.concatenatedVideo || company.concatenatedVideo.status !== VIDEO_FINISHED_STATUS) {
      return null
    }

    const result = []

    for (const topic of TOPICS) {
      for (const segment of topic.segments) {
        const data = company.videoSegments.find(x => x.subject === segment.id)

        if (data) {
          const duration = durationToSeconds(data.duration)

          if (!duration) {
            return null
          }

          result.push({
            id: data.id,
            title: segment.title,
            duration
          })
        }
      }
    }

    return result
  }, [company])
}

export function useDownloadVideoUrl(company) {
  return useMemo(() => {
    if (company.concatenatedVideo && company.concatenatedVideo.binary) {
      const match = company.concatenatedVideo.binary.match(/[^/]+$/)

      if (match) {
        return '/download/' + match[0]
      }
    }

    return null
  }, [company])
}
