import css from './RatingModal.module.sass'

import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import omit from 'lodash.omit'

import Button from './Button'
import { Form, FormFooter, FormRow, FormCol, TextArea, RatingField } from './Form'
import Modal from './Modal'
import Text from './Text'
import Ionicon from './Ionicon'
import Loader from './Loader'
import {
  MAP_RATING_QUESTIONS, PRODUCT_NAME,
  // PRODUCT_NAME_SHORT, 
} from "../constants/enterpriseConfig"

const GET_INVESTOR_COMPANY_RATING = gql`
  query getInvestorCompanyRating ($company: UUID) {
    getInvestorCompanyRating(company: $company) {
      rating1
      rating2
      rating3
      rating4
      rating5
      ratingText
    }
  }
`

const RATE_COMPANY = gql`
  mutation rateCompany ($input: RateCompanyMutationInput!) {
    rateCompany(input: $input) {
      obj {
        id
      rating1
      rating2
      rating3
      rating4
      rating5
      ratingText
      updatedAt
      }
      errors {
        field
        messages
      }
    }
  }
`

const RatingModal = ({ company, defaultOverall, onClose, onRate }) => {
  const [formData, setFormData] = useState({})


  const { data: ratingData, loading: fetching } = useQuery(GET_INVESTOR_COMPANY_RATING, {
    variables: { company: company.id },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (ratingData) {
      setFormData({
        ...omit(ratingData.getInvestorCompanyRating, ['__typename'])
      })
    }
  }, [ratingData, defaultOverall])

  const [rateCompany, { saving }] = useMutation(RATE_COMPANY, {
    variables: {
      input: {
        company: company.id,
        ...formData
      }
    },
    onCompleted: ({ rateCompany }) => {
      if (rateCompany.errors) {
        return console.error(rateCompany.errors)
      }
      onRate(rateCompany.obj.rating1)
      onClose()
    },
  })

  const handleFieldUpdate = useCallback((patch) => {
    setFormData(x => ({ ...x, ...patch }))
  }, [])

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    rateCompany()
  }, [rateCompany])

  return (
    <Modal
      variant='welcome'
      title={<>
        <Text tag="h1" variant="h2" color="white">
          Submit Rating on {company.name}
        </Text>

        <Button variant="icon" className={css.btnClose} onClick={onClose}>
          <Ionicon name="close" size="32" color="white" />
        </Button>
      </>}
      onOverlayClick={onClose}
      noButtons
    >
      {fetching
        ? <Loader />
        : (
          <Form onSubmit={handleSubmit}>
            <FormRow>
              <FormCol>
                <Text tag="h3" italic offset="double-bottom" >
                  Enter your rating on a 5-star rating scale, with 1 being the lowest rating and 5 being the highest rating.
                </Text>
                <Text tag="h3" weight='500'>
                  {(Object.prototype.hasOwnProperty.call(MAP_RATING_QUESTIONS, "rating1") ?
                    MAP_RATING_QUESTIONS["rating1"] :
                    `rating1 ${PRODUCT_NAME.toLowerCase()} rating1`)}
                </Text>
                <RatingField
                  name="rating1"
                  value={formData.rating1}
                  focused
                  required
                  onUpdate={handleFieldUpdate}
                />
              </FormCol>
            </FormRow>

            <FormRow>
              <FormCol>

                <FormRow offset="15">
                  <FormCol>
                    <Text tag="h3" weight='500'>
                      {(Object.prototype.hasOwnProperty.call(MAP_RATING_QUESTIONS, "rating2")
                        ? MAP_RATING_QUESTIONS["rating2"] :
                        'Rating2')}
                    </Text>
                    <RatingField
                      name="rating2"
                      value={formData.rating2}
                      clearable
                      onUpdate={handleFieldUpdate}
                      required
                    />
                  </FormCol>
                </FormRow>

                <FormRow offset='15'>
                  <FormCol>
                    <Text tag="h3" weight='500'>
                      {(Object.prototype.hasOwnProperty.call(MAP_RATING_QUESTIONS, "rating3")
                        ? MAP_RATING_QUESTIONS["rating3"] :
                        'Rating3')}
                    </Text>
                    <RatingField
                      name="rating3"
                      value={formData.rating3}
                      clearable
                      onUpdate={handleFieldUpdate}
                      required
                    />
                  </FormCol>
                </FormRow>

                <FormRow offset='15'>
                  <FormCol>
                    <Text tag="h3" weight='500'>
                      {(
                        Object.prototype.hasOwnProperty.call(MAP_RATING_QUESTIONS, "rating4")
                          ? MAP_RATING_QUESTIONS["rating4"] :
                          'Rating4')}
                    </Text>
                    <RatingField
                      name="rating4"
                      value={formData.rating4}
                      clearable
                      onUpdate={handleFieldUpdate}
                      required
                    />
                  </FormCol>
                </FormRow>

                <FormRow offset='15'>
                  <FormCol>
                    <Text tag="h3" weight='500'>
                      {(Object.prototype.hasOwnProperty.call(
                        MAP_RATING_QUESTIONS, "rating5")
                        ? MAP_RATING_QUESTIONS["rating5"] :
                        'Rating5')}
                    </Text>
                    <RatingField
                      name="rating5"
                      value={formData.rating5}
                      clearable
                      onUpdate={handleFieldUpdate}
                      required
                    />
                  </FormCol>
                </FormRow>
              </FormCol>
            </FormRow>

            <FormRow offset='30'>
              <FormCol>
                <Text variant="standard" weight='500' offset="quarter-bottom">
                  Comments:
                </Text>
                <br />
                <Text variant="standard" weight='400' offset="quarter-bottom">
                  Please provide any comments you may have about this company. If you recommended a PoC, is that realistic for AO 2023 or the first 3 months of 2023? If you didn’t recommend a PoC, would you like to pick up the conversation or track the start up after the AO is finished?
                </Text>
                <TextArea
                  name="ratingText"
                  value={formData.ratingText}
                  rows="5"
                  onUpdate={handleFieldUpdate}
                />
              </FormCol>
            </FormRow>

            <FormFooter offset='30'>
              <Button type='submit' variant='outline' onClick={onClose}>Cancel</Button>
              <Button type='submit' variant='primary' disabled={saving}>Submit</Button>
            </FormFooter>
          </Form>
        )
      }
    </Modal>
  )
}

RatingModal.propTypes = {
  company: PropTypes.object,
  defaultOverall: PropTypes.number,
  onClose: PropTypes.func,
  onRate: PropTypes.func
}

export default RatingModal
