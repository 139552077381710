import css from './DeleteConnection.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import Button from "../Button"
import Ionicon from "../Ionicon"
import {useMutation} from "react-apollo";
import gql from "graphql-tag";
const DELETE_SAVED_COMPANY = gql`
  mutation deleteSavedCompany ($id: Int!) {
    deleteSavedCompany(id: $id) {
      id
    }
  }
`

const DeleteConnection = ({ connectionId, deleteCallBack }) => {
    const [deleteSavedCompany, {loading, error}] = useMutation(DELETE_SAVED_COMPANY, {
        onCompleted: () => {
            if(deleteCallBack){
                deleteCallBack()
            }
        },
        onError: () => {
            console.log(error)
        }
    })

    const handleClick = () =>{
        deleteSavedCompany({ variables: { id: connectionId } })
    }

    return (
        <Button disabled={loading}
                variant='iconEditor'
                icon={
                    <Ionicon name='trash' size='24' color='red' />}
                className={css.deleteButton} onClick={handleClick} />
    )
}

DeleteConnection.propTypes = {
    connectionId: PropTypes.number,
    deleteCallBack: PropTypes.func
}

export default DeleteConnection