import css from './WelcomeModal.module.sass'

import React from 'react'

import Button from './Button'
import Modal from './Modal'
import Text from './Text'

import { CREATE_PATH } from '../constants/routes'
import { PRODUCT_NAME } from "../constants/enterpriseConfig"

const WelcomeModal = () => {

  return (
    <Modal
      variant="welcome"
      title={<Text tag="h1" variant="h2" color="white">Welcome!</Text>}
      buttons={
        <Button to={CREATE_PATH} variant="primary">
          Create {PRODUCT_NAME.toLocaleLowerCase()}
        </Button>
      }
    >
      <div className={css.content}>
        <Text tag="p" variant="larger" weight="400" offset="single-bottom">
          Thanks for signing up and wanting to be part of our world.
        </Text>
        <Text tag="p" variant="larger" weight="400" offset="single-bottom" className={css.p}>
          We&apos;re on the constant look out for ambitious founders on a path to achieving amazing
          things in sport, health and entertainment so can&apos;t wait to learn more about what your team
          is up to.
        </Text>
        <Text tag="p" variant="larger" weight="400" offset="single-bottom" className={css.p}>
          Click the button below to start creating your {PRODUCT_NAME.toLocaleLowerCase()}.
        </Text>
      </div>
    </Modal>
  )
}

export default WelcomeModal
