import css from './SubmittedModal.module.sass'
import Modal from "../Modal"
import Text from "../Text"
import React from "react"
import PropTypes from "prop-types"
import { PRODUCT_NAME } from "../../constants/enterpriseConfig"


const SubmittedModal = (props) => {

  return (
    <Modal
      buttons={props.buttons}
      visible={props.visible}
    >
      <div className={css.congratsModalContainer}>
        <Text tag="p" variant="h3">
          Congratulations!
        </Text>
        <Text tag="p" variant="h3" className={css.middle}>
          Your {PRODUCT_NAME.toLowerCase()} has been submitted.
        </Text>
      </div>
    </Modal>
  )
}

SubmittedModal.propTypes = {
  buttons: PropTypes.node,
  visible: PropTypes.bool
}


export default SubmittedModal
