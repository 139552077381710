import React from 'react'
import { Consumer as DevicesConsumer } from "../components/DevicesContext"
import Layout from "../components/Layout"
import { SAVED_MODULE_ENABLED } from '../constants/enterpriseConfig'
import { Redirect } from "react-router-dom"
import { HOME_PATH } from "../constants/routes"
import SavedCompanies from '../components/SavedCompanies'

const SavedCompaniesScreen = () => {

  if (!SAVED_MODULE_ENABLED)
    return <Redirect to={HOME_PATH} />
  return (
    <DevicesConsumer>
      {() =>
        <Layout
          variant="fullHeight"
          noFooter
          background="white"
        >
          <SavedCompanies />
        </Layout>
      }
    </DevicesConsumer>
  )
}

SavedCompaniesScreen.propTypes = {
}

export default SavedCompaniesScreen
