import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import get from 'lodash.get'

import Layout from '../components/Layout'

import MetaDescription from '../components/MetaDescription'
import Alert from '../components/Alert'
import Loader from '../components/Loader'
import { Consumer as CurrentUserConsumer } from '../components/CurrentUserContext'
import PitchtapeReview from '../components/PitchtapeReview'

import { formatGraphQLError } from '../helpers/errors'
import { usePitchtapeTracking } from '../helpers/hooks'
import { GET_COMPANY } from '../constants/queries'
import { HOME_PATH } from '../constants/routes'

const StartupScreen = ({ match }) => {
  const { data, loading, error, client } = useQuery(GET_COMPANY, {
    fetchPolicy: 'network-only',
    variables: { id: match.params.companyId }
  })

  const company = get(data, 'company')

  usePitchtapeTracking(company)

  const handleRate = useCallback((rating) => {
    client.writeQuery({
      query: GET_COMPANY,
      variables: { id: match.params.companyId },
      data: {
        company: {
          ...data.company,
          givenAvgRating: rating
        }
      }
    })
  }, [match.params.companyId, data, client])

  return (
    <Layout background='white' noFooter>
      <CurrentUserConsumer>
        {({ currentUser }) => {
          if (loading) {
            return <Loader offset='single' />
          }

          if (error) {
            return <Alert variant='error'>{formatGraphQLError(error)}</Alert>
          }

          if (company) {
            return (
              <>
                <MetaDescription content={
                  `${company.name}, ${company.description} is on Pitchtape.
                  Check out their video pitch and sign up as an investor to connect with ${company.name}.`
                } />

                <PitchtapeReview
                  company={company}
                  forInvestor={currentUser && currentUser.accountType === 'investor'}
                  onRate={handleRate}
                  navigationButtons={false}
                />
              </>
            )
          }

          return <Redirect to={HOME_PATH} />
        }}
      </CurrentUserConsumer>
    </Layout>
  )
}

StartupScreen.propTypes = {
  match: PropTypes.object
}

export default StartupScreen
