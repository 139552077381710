import React from 'react'
import { Consumer as DevicesConsumer } from '../components/DevicesContext'
import Layout from '../components/Layout'
import { METRICS_MODULE_ENABLED } from '../constants/enterpriseConfig'
import { Redirect } from "react-router-dom"
import { HOME_PATH } from "../constants/routes"
import Metrics from '../components/Metrics'

const MetricsScreen = () => {

  if (!METRICS_MODULE_ENABLED)
    return <Redirect to={HOME_PATH} />
  return (
    <DevicesConsumer>
      {() =>
        <Layout
          variant="fullHeight"
          noFooter
          background="white"
        >
          <Metrics />
        </Layout>
      }
    </DevicesConsumer>
  )
}

MetricsScreen.propTypes = {
}

export default MetricsScreen
