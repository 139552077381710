import css from './index.module.sass'

import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import SectionTitle from '../SectionTitle'
import Text from '../Text'
import Link from '../Link'
import Ionicon from '../Ionicon'
import TemplateTopic from './TemplateTopic'

import { TOPICS } from '../../constants/videos'
const TeleprompterScriptForm = ({ company }) => {
  const { teleprompts } = company
  const [open, setOpen] = useState(false)

  const handleAllOpenClick = useCallback(() => {
    setOpen(x => !x)
  }, [])

  return (
    <section>
      <SectionTitle
        title='2. Prepare Teleprompter Script'
      />

      <div className={css.intro}>
        <Text variant="standardLarger" offset="single-bottom" weight="500" tag="h2">Intructions:</Text>
        <Text variant="standardLarger" tag="p" weight="300" offset="single-bottom">
          The topics to be included in your video are listed below. You can use the text fields
          below to prepare a script that will automatically display on your screen as a
          teleprompter while you record your video.
        </Text>
        <Text variant="standardLarger" tag="p" weight="300" offset="single-bottom">
          Please limit your entire video to <Text variant={null} weight="500">5 minutes total</Text>,
          which gives you about <Text variant={null} weight="500">35 seconds</Text> for each topic.
          When writing your script, this corresponds to roughly <Text variant={null} weight="500">
            65-110 words</Text> for each topic.
        </Text>

      </div>

      <Text tag='h3' variant='blockTitle' weight='400' offset='double-bottom'>
        Video Topics:
      </Text>

      <Link className={css.heading} color='deepBlue' onClick={handleAllOpenClick} >
        <i className={css.headingIcon}>
          <Ionicon name={open ? 'remove' : 'add'} size='24' color='blue' />
        </i>

        <Text variant='standardLarger'>
          <Text variant={null} weight='500'>{open ? 'Collapse all' : 'Expand all'}</Text>
        </Text>
      </Link>
      <div className={css.topics}>
        {TOPICS.map((topic, index) =>
          <TemplateTopic
            key={topic.id}
            index={index}
            topic={topic}
            teleprompts={teleprompts}
            defaultOpen={open}
          />
        )}
      </div>
    </section>
  )
}

TeleprompterScriptForm.propTypes = {
  company: PropTypes.object
}

export default TeleprompterScriptForm
