export const VIDEO_PITCH_TEMPLATE_URL = 'https://docs.google.com/document/d/1aEN0i5_Z5w1VyofxC9swlMlUqCTMfI3hmYfVjB6Hg_Q/edit'
export const RECORDING_TIPS_URL = 'https://docs.google.com/document/d/1cUZrG3NYlIKzy4TWtU4OIlkq-nIXEhiQ0eyPurpfUcA/edit'
export const TECHNICAL_REQUIREMENTS_URL = 'https://docs.google.com/document/d/1GCWoiY0rXVvY-Zfy8dJdG7Lfl4Kvd_knKSJvS7otcZU/edit'
export const NBA_TERMS_URL = 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=065f1cd8-2f5a-42b7-83d1-9c7b3dd31156&env=na2&acct=beeca2ce-bec9-4726-98ae-3ce2fe6c3fb7&v=2'
export const NBA_TUTORIAL_URL = 'https://www.youtube.com/watch?v=ThAHtdmZ2pE'

export const SURVEY_URL = 'https://forms.gle/7XYHT6MYSK1ZVYwM6'
export const SCHEDULE_1_ON_1_URL = 'https://calendly.com/pitchtape/60min'

export const HLS_JS_URL = 'https://cdn.jsdelivr.net/npm/hls.js@latest/dist/hls.min.js'
export const STRIPE_JS_URL = 'https://checkout.stripe.com/checkout.js'
export const PDF_JS_URL = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.2.228/build/pdf.min.js'
