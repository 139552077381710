import css from './OtherInfo.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import Text from '../Text'
import SectionHeading from './SectionHeading'
import Link from '../Link'
import { removeProtocol, isUrl, addProtocol } from '../../helpers/strings'

const OtherInfo = ({ company }) => {
  const social =  company?.socialHandles && company?.socialHandles[0] ? company?.socialHandles[0].split(',') : []
  const socialHandleArray = social.map((el) => el.replace(' ', ''))

  return (
    <section className={css.container}>
      <div className={css.content}>
        <SectionHeading icon="bookBlack">
          Info
        </SectionHeading>

        <div className={css.otherInfoContainer}>
          <div className={css.otherInfoRow}>
            <Text weight='500' variant='large'>WEBSITE URL</Text>
            <Link color='black' external href={company?.websiteUrl}>
              {company?.websiteUrl && removeProtocol(company?.websiteUrl)}
            </Link>
          </div>
          <div className={css.otherInfoRow}>
            <Text weight='500' variant='large'>SOCIAL HANDLES</Text>
            {
              socialHandleArray.map((social, idx) => {
                let element = null
                if (isUrl(social)) {
                  element = (<Link variant='inherit' external href={addProtocol(social)} key={idx}>
                    {removeProtocol(social)}
                  </Link>)
                } else {
                  element = (<Text key={idx}>{social}</Text>)
                }
                return element
              }
              )
            }</div>
          <div className={css.otherInfoRow}>
            <Text weight='500' variant='large'>CLIENTS</Text>
            <Text>{company?.clients}</Text>
          </div>
          <div className={css.otherInfoRow}>
            <Text weight='500' variant='large'>INVESTORS</Text>
            <Text>{company?.investors}</Text>
          </div>
          <div className={css.otherInfoRow}>
            <Text weight='500' variant='large'>DATE OF LATEST FUNDING</Text>
            <Text>{company?.dateLatestFunding}</Text>
          </div>
          <div className={css.otherInfoRow}>
            <Text weight='500' variant='large'>VALUATION AT LAST ROUND OF FUNDING</Text>
            <Text>{company?.currentValuation}</Text>
          </div>
          <div className={css.otherInfoRow}>
            <Text weight='500' variant='large'>TOTAL FUNDING RAISED TO DATE</Text>
            <Text>{company?.totalFundingRaisedToDate}</Text>
          </div>
        </div>
      </div>
    </section>
  )
}

OtherInfo.propTypes = {
  company: PropTypes.object
}

export default OtherInfo
