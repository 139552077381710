export const PRIMARY_COLOR = '#007FAD'

export const INFO_COLOR = [
  {
    name: 'pitches',
    value: '#23D3D3'
  },
  {
    name: 'ratings',
    value: '#0F5FD5'
  },
  {
    name: 'avgRatings',
    value: '#FBC02D'
  },
  {
    name: 'favorites',
    value: '#E94040'
  },
  {
    name: 'users',
    value: '#0F5FD5'
  },
  {
    name: 'creators',
    value: '#45BA89'
  },
  {
    name: 'reviewers',
    value: '#FBC02D'
  },
]
