import css from './Mobile.module.sass'

import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery, useMutation } from 'react-apollo'
import { isIOS } from 'react-device-detect'

import Alert from '../Alert'
import AnimatedEllipsis from '../AnimatedEllipsis'
import Asterisk from '../Asterisk'
import Button from '../Button'
import Ionicon from '../Ionicon'
import Link from '../Link'
import Loader from '../Loader'
import Text from '../Text'
import VideoPlayer from '../VideoPlayer'
import VideoRecorderMobile from '../VideoRecorderMobile'
import Topics from './Topics'

import { GET_USER_COMPANY } from '../../constants/queries'
import { VIDEO_FINISHED_STATUS } from '../../constants/videos'
import { GTM_PITCH_VIDEOS_RECORDED_EVENT } from '../../constants/gtm'
import { formatGraphQLError } from '../../helpers/errors'
import { gtmTrack } from '../../helpers/tracking'
import { hasRequiredVideos } from '../../helpers/companies'
import { durationToSeconds } from '../../helpers/videos'
import Modal from "../Modal"
import { injectParams } from '../../helpers/routes'
import { CREATE_PATH } from '../../constants/routes'
import { formatSecondsAsTime } from '../../helpers/dates'


const STITCH_COMPANY_VIDEOS = gql`
  mutation stitchCompanyVideos {
    stitchCompanyVideos {
      code
      message
    }
  }
`

const VideosRecorderMobile = ({
  topics, topic, segment, previousSegmentUrl, nextSegmentUrl, practice, saving, exitUrl,
  renderPromptModal, onBeforeSave, onSave, onStitch,
  history
}) => {
  const [showTopics, setShowTopics] = useState(true)
  const [recording, setRecording] = useState(practice)
  const [recorderKey, setRecorderKey] = useState(topic + segment)
  const [promptExitRecorder, setPromptExitRecorder] = useState()
  const [validationTopics, setValidationTopics] = useState()
  const [validationVideo, setValidationVideo] = useState()

  const { data, loading, error } = useQuery(GET_USER_COMPANY)

  const [stitchCompanyVideos,
    { loading: stitching, error: stitchingError }] = useMutation(STITCH_COMPANY_VIDEOS, {
      onCompleted: () => {
        const firstTime = !data.company.concatenatedVideo
        if (firstTime) {
          gtmTrack(GTM_PITCH_VIDEOS_RECORDED_EVENT, {
            pitchtape: { id: data.company.id }
          })
        }

        onStitch(firstTime)
      },
      onError: () => {
        console.log('There was an error using company Stitch Videos')
      }
    })
  const exitFullScreen = () => {
    const doc = window.document
    const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen
    if (!isIOS && (doc.fullscreenElement || doc.mozFullScreenElement || doc.webkitFullscreenElement || doc.msFullscreenElement)) {
      cancelFullScreen.call(doc)
    }
  }

  const setFullScreen = () => {
    const doc = window.document
    const docEl = doc.documentElement
    const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
    if (!isIOS && !doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl)
    }
  }

  const totalSegmentDuration = () => {
    let totalSegmentDuration = 0

    topics.map((topic) => {
      return topic?.segments.map(segment => {
        const time = recordedSegments.find(el => el.title === segment.id)?.duration || 0
        return totalSegmentDuration += time
      })
    })
    return formatSecondsAsTime(totalSegmentDuration)
  }

  useEffect(() => {
    !showTopics && setFullScreen()
    showTopics && exitFullScreen()
  }, [showTopics])

  const toggleShowTopics = () => {
    setShowTopics(!showTopics)
    setValidationTopics(false)
    setValidationVideo(false)
  }

  const toggleValidationTopics = () => {
    if (validationVideo) {
      setValidationTopics(true)
    } else {
      setShowTopics(!showTopics)
    }
  }

  const handleValidTopic = () => {
    setValidationVideo(true)
  }

  useEffect(() => {
    setRecording(practice)
    setRecorderKey(topic + segment)
  }, [topic, segment, practice])

  const recordedSegments = useMemo(() => {
    return data && data.company.videoSegments.map(v => ({ title: v.subject, duration: durationToSeconds(v.duration) }))
  }, [data])

  const handleSave = useCallback((take, metadata) => {
    setValidationVideo(false)
    const handleSaveCompleted = () => {
      if (nextSegmentUrl) {
        history.push(nextSegmentUrl)
      } else if (!practice) {
        setRecording(false)
      }
    }

    if (take.binary) {
      return handleSaveCompleted()
    }

    onSave({
      binary: take,
      subject: segment,
      duration: metadata
    })
      .then(() => handleSaveCompleted())
  }, [history, segment, nextSegmentUrl, practice, onSave])

  const handleComplete = useCallback(() => {
    if (!stitching) {
      stitchCompanyVideos()
    }
  }, [stitching, stitchCompanyVideos])

  const renderPrevNextButtons = () =>
    <div className={css.prevNextButtons}>
      {previousSegmentUrl &&
        <Button
          variant='outline'
          to={previousSegmentUrl}
          icon={<Ionicon name='arrowDropleft' size='48' style={{ marginRight: -10, marginLeft: -5 }} />}
        >Previous</Button>
      }

      {nextSegmentUrl &&
        <Button
          variant='outline'
          to={nextSegmentUrl}
          icon={<Ionicon name='arrowDropright' size='24' style={{ marginLeft: -10, marginRight: -5 }} />}
          iconPosition='after'
        >Next</Button>
      }
    </div>

  if (loading) {
    return <Loader variant='centered' />
  }

  if (error) {
    return <Alert variant='error'>{formatGraphQLError(error)}</Alert>
  }

  const segmentData = topics.find(t => t.id === topic).segments.find(s => s.id === segment)
  const videoData = data.company.videoSegments.find(v => v.subject === segment)
  const promptData = data.company.teleprompts.find(t => t.subject === segment)
  const concatenatedVideo = data.company.concatenatedVideo

  const renderStitchVideoSegmentsButton = () => {
    if (!practice)
      return (
        <>
          {concatenatedVideo && concatenatedVideo.status !== VIDEO_FINISHED_STATUS
            ? (
              <Button
                variant='primary'
                disabled
              >
                Processing video<AnimatedEllipsis />
              </Button>
            )
            : (
              <>
                <Button
                  variant='primary'
                  disabled={
                    hasRequiredVideos(data.company) ||
                    (concatenatedVideo && concatenatedVideo.videoIsUpToDate) ||
                    stitchingError !== undefined
                  }
                  onClick={handleComplete}
                >
                  Stitch video
                </Button>
              </>
            )
          }
        </>)
  }

  return (
    <>
      {stitchingError &&
        <Alert variant='error'>{formatGraphQLError(stitchingError)}</Alert>
      }
      <div className={css.container}>
        {
          showTopics
            ?
            <div className={css.topicsContainer}>
              <div className={css.btnExit}>
                <Link to={exitUrl} onClick={(evt) => {
                  if (
                    !practice &&
                    //     companyRequiresStitchVideos(data.company) &&
                    !hasRequiredVideos(data.company) &&
                    !(concatenatedVideo && concatenatedVideo.videoIsUpToDate)
                  ) {
                    evt.preventDefault()
                    setPromptExitRecorder(true)
                    return false
                  }
                }}
                >
                  <Text weight='700'>{practice ? 'Exit Practice Mode' : 'Exit Recorder'}</Text>
                </Link>
              </div>
              <aside className={css.topics}>
                {
                  <>
                    <Text variant='blockTitle' tag='h1' offset='quarter-bottom'>
                      Record Video <Asterisk />
                    </Text>
                    <Text tag="p" variant="light" italic offset="single-bottom">
                      Record your video one topic at a time, and our tech will stitch your topics
                      together into one video. To record a video for a topic, click on the topic name.
                    </Text>
                    <Text tag="p" variant="light" italic offset="single-bottom">
                      Please limit your entire video to&nbsp;
                      <Text variant={null} weight="500">5 minutes total</Text>, which gives you about&nbsp;
                      <Text variant={null} nowrap weight="500">35 seconds per topic.</Text>
                    </Text>
                    <Text tag="p" variant="light" italic offset="double-bottom">
                      Once all topics are recorded, click on the Stitch Video button to complete your video.
                    </Text>
                    <Text variant="blockTitle" tag="h2" weight="400">
                      Video Topics:
                    </Text>
                  </>
                }
                <Topics
                  topics={topics}
                  topic={topic}
                  segment={segment}
                  recordedSegments={recordedSegments}
                  onToggleShowTopics={toggleShowTopics}
                />

                {
                  <div className={css.containerTotalLength}>
                    <Text className={css.totalLength}> Total video length: {totalSegmentDuration()}</Text>
                  </div>
                }
                {promptExitRecorder &&
                  <Modal
                    buttons={<>
                      {renderStitchVideoSegmentsButton()}
                      <Button variant='outline' onClick={() => {
                        setPromptExitRecorder(false)
                      }
                      }>
                        Cancel
                      </Button>
                      <Button variant='outline' onClick={() => history.replace(
                        injectParams(CREATE_PATH)
                      )} disabled={saving}>
                        Exit video Recorder
                      </Button>
                    </>}
                  >
                    <Text tag='p' variant='standardLarger' centered>
                      Are you sure you want to exit the Video Recorder before stitching your video?
                    </Text>
                    <Text tag='p' offset='single-top' variant='standardLarger' centered>
                      Any changes made to your video topics will not be updated in your Pitch Video until you click
                      on the Stitch Video button and your video has completed stitching.
                    </Text>
                  </Modal>
                }

                {!practice &&
                  <>
                    {concatenatedVideo && concatenatedVideo.status !== VIDEO_FINISHED_STATUS
                      ? (
                        <Button
                          variant='primary'
                          disabled
                        >
                          Processing video<AnimatedEllipsis />
                        </Button>
                      )
                      : (
                        <>
                          <Button
                            variant='primary'
                            disabled={
                              hasRequiredVideos(data.company) ||
                              (concatenatedVideo && concatenatedVideo.videoIsUpToDate) ||
                              stitchingError !== undefined
                            }
                            onClick={handleComplete}
                            block
                          >
                            Stitch video
                          </Button>
                        </>
                      )
                    }
                  </>
                }

              </aside>
            </div>
            :
            <div className={css.recorder}>
              <div className={css.goToTopics}>
                <Button
                  variant='primary'
                  offset='single-left'
                  onClick={() => toggleValidationTopics()}
                >
                  <Text variant='button'>
                    Topics
                  </Text>
                </Button>
              </div>
              {validationTopics &&
                <Modal
                  buttons={<>
                    <Button variant='outline' onClick={() => {
                      toggleShowTopics()
                    }
                    }>
                      Yes
                    </Button>
                    <Button variant='primary' onClick={() => {
                      setValidationTopics(false)
                    }}
                    >
                      No
                    </Button>
                  </>}
                >
                  <Text tag='p' variant='standardLarger' centered>
                    Are you sure you&apos;d like to exit the recording without saving?
                  </Text>
                </Modal>
              }
              <div className={css.recorderInner}>
                {(!videoData || recording)
                  ? (
                    <VideoRecorderMobile
                      key={recorderKey}
                      subject={segmentData.id}
                      title={segmentData.question}
                      teleprompt={promptData && promptData.script}
                      telepromptExample={segmentData.teleprompt}
                      lastTake={videoData}
                      saving={saving}
                      saveButtonText={
                        practice
                          ? 'I’m ready! Record my pitch'
                          : 'Save'
                      }
                      practice={practice}
                      renderPromptModal={renderPromptModal}
                      onBeforeSave={onBeforeSave}
                      onSave={handleSave}
                      validTopics={handleValidTopic}
                    />
                  )
                  : (
                    <>
                      <VideoPlayer
                        binary={videoData.binary}
                        hlsReady={videoData.hlsReady}
                        hlsUrl={videoData.hlsUrl}
                      />

                      <div className={css.buttons}>
                        <Button variant='outline' onClick={() => setRecording(true)}>Retake</Button>

                        {renderPrevNextButtons()}
                      </div>
                    </>
                  )
                }
              </div>
            </div>
        }
      </div>
    </>)
}

VideosRecorderMobile.propTypes = {
  topics: PropTypes.array,
  topic: PropTypes.string,
  segment: PropTypes.string,
  previousSegmentUrl: PropTypes.string,
  nextSegmentUrl: PropTypes.string,
  practice: PropTypes.bool,
  saving: PropTypes.bool,
  exitUrl: PropTypes.string,
  renderPromptModal: PropTypes.func,
  onBeforeSave: PropTypes.func,
  onSave: PropTypes.func,
  onStitch: PropTypes.func,
  history: PropTypes.object
}

export default withRouter(VideosRecorderMobile)
