import css from './index.module.sass'

import React, { useContext } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from "prop-types"

import AuthNav from './AuthNav'
import Button from '../Button'
import Logo from '../Logo'
import PoweredBy from '../PoweredBy'

import CurrentUserContext from '../CurrentUserContext'
import DevicesContext from '../DevicesContext'

import { HEADER_CLASSNAME } from '../../constants/classnames'
import { STARTUP_PATH, SIGNIN_PATH, SIGNUP_PATH } from '../../constants/routes'
import { VIDEO_CREATOR_PLURAL, VIDEO_REVIEWER_PLURAL, SIGNUP_REVIEWER_ENABLED } from "../../constants/enterpriseConfig"
import HamburguerMenu from './HamburguerMenu'

const Header = ({ noSignup }) => {
  const { currentUser: { accountType }, loading } = useContext(CurrentUserContext)
  const { mobile } = useContext(DevicesContext)


  const location = useLocation()
  const queryFounderSignUpUrl = matchPath(location.pathname, { path: SIGNUP_PATH })
  const isFounderSignUpInQuery = queryFounderSignUpUrl?.params.accountType === 'founder'
  const isInvestorSingUpInQuery = queryFounderSignUpUrl?.params.accountType === 'investor'

  return (
    <header className={classNames(css.container, HEADER_CLASSNAME)}>
      <div className={css.floating}>
        <div className={css.logo}>
          <Logo />
        </div>

        {!loading && accountType && <AuthNav />}
        {!noSignup && !loading && !accountType ?
          !mobile ?
            <div className={css.right}>
              {matchPath(location.pathname, { path: STARTUP_PATH }) && <PoweredBy />}

              {SIGNUP_REVIEWER_ENABLED ?
                <>
                  {!isInvestorSingUpInQuery &&
                    <Button variant={'secondary-color'} to={SIGNUP_PATH}
                      params={{ accountType: 'investor' }} className={css.singUpButton}>
                      {VIDEO_REVIEWER_PLURAL} sign up
                    </Button>
                  }
                  {!isFounderSignUpInQuery &&
                    <Button variant={'secondary-color'} to={SIGNUP_PATH}
                      params={{ accountType: 'founder' }} className={css.singUpButton}>
                      {VIDEO_CREATOR_PLURAL} sign up
                    </Button>
                  }
                </>
                :
                <>
                  {!isFounderSignUpInQuery && !isInvestorSingUpInQuery &&
                    <Button variant={'secondary-color'} to={SIGNUP_PATH}
                      params={{ accountType: 'founder' }} className={css.singUpButton}>
                      {VIDEO_CREATOR_PLURAL} sign up
                    </Button>
                  }
                </>
              }

              {!matchPath(location.pathname, { path: STARTUP_PATH }) &&
                <Button variant="login" to={SIGNIN_PATH}>Log in</Button>
              }
            </div>
            : <HamburguerMenu options={[
              {
                to: SIGNUP_PATH,
                params: { accountType: 'founder' },
                text: `${VIDEO_CREATOR_PLURAL} sign up`,
              },
              {
                to: SIGNIN_PATH,
                text: 'Log in',
              }
            ]} />
          : null
        }
      </div>
    </header>
  )
}

Header.propTypes = {
  noSignup: PropTypes.bool
}


export default Header
