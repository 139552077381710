import css from './CompanyDetails.module.sass'

import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'
import List from '../List'
import Ionicon from '../Ionicon'

import DevicesContext from '../DevicesContext'
import { formatLocations } from '../../helpers/strings'

export const formatProperty = (property) => {
  if (Array.isArray(property)) {
    return property.map(x => x.name).join(', ')
  } else if (property !== null && typeof property === 'object') {
    return property.name
  } else if (typeof property === 'boolean') {
    return property ? 'Yes' : 'No'
  }

  return property
}

const CompanyDetails = ({ company, iconColor = 'white' }) => {
  const { mobile, mobileUa } = useContext(DevicesContext)

  return (
    <>
      <Text variant='h2' tag='h1' weight='400' truncated>{company.name}</Text>
      <Text variant='h3' tag='p' offset='half-bottom'>{company.description}</Text>

      <List variant='horizontal' gap='30' offset='5'>
        <div className={css.container}>
          {Boolean(company.locations.length) &&
            <List.Item>
              <Ionicon
                name={!mobile && !mobileUa ? 'location_white' : 'location_black'}
                color={iconColor}
                offset='right-5'
                style={{ verticalAlign: -3 }} />
              {formatLocations(company.locations)}
            </List.Item>
          }

          <List.Item>
            <Ionicon
              name={!mobile && !mobileUa ? 'industry_white' : 'industry'}
              color={iconColor}
              offset='right-5'
              style={{ verticalAlign: -3 }}

            />
            {formatProperty(company.industries)}
          </List.Item>

          <List.Item>
            <Ionicon
              name={!mobile && !mobileUa ? 'customer_category_white' : 'customer_category_black'}
              color={iconColor}
              offset='right-5'
              style={{ verticalAlign: -3 }}

            />
            {formatProperty(company.customerCategory)}
          </List.Item>

          <List.Item>
            <Ionicon
              name={!mobile && !mobileUa ? 'stage_white' : 'stage'}
              color={iconColor}
              offset='right-5'
              style={{ verticalAlign: -2 }}

            />
            {formatProperty(company.stage)}
          </List.Item>
          <List.Item>
            <Ionicon name={!mobile && !mobileUa ? 'tennis_white' : 'tennis_black'}
              color={iconColor} offset='right-5' style={{ verticalAlign: -2 }} />
            {formatProperty(company.applicationTrack)}
          </List.Item>
        </div>
      </List>
    </>
  )
}

CompanyDetails.propTypes = {
  company: PropTypes.object,
  iconColor: PropTypes.string
}

export default CompanyDetails
