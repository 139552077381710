import css from './SubmitToFeedModal.module.sass'
import React from 'react'

import Modal from '../Modal'
import Text from '../Text'
import PropTypes from "prop-types";
import { formatGraphQLError } from "../../helpers/errors"
import Alert from "../Alert"
import { PRODUCT_NAME } from "../../constants/enterpriseConfig"


const SubmitToFeedModal = (props) => {

  return (
    <Modal
      buttons={props.buttons}
      visible={props.visible}
      loading={props.loading}
    >
      <div className={css.confirmModalContainer}>

        <Text tag='p' variant='h3'>
          Ready to submit your {PRODUCT_NAME.toLowerCase()}?
        </Text>
        <Text tag='p' variant='standardLarger' className={css.confirmNote}>
          Click the submit button to submit your {PRODUCT_NAME.toLowerCase()}.
        </Text>
        <Text tag='p' variant='standardLarger' className={css.confirmNote}>
          Note: You can edit your {PRODUCT_NAME.toLowerCase()} at any time up until the submission deadline.
          Any edits made after submitting your {PRODUCT_NAME.toLowerCase()} will update your submission in real time,
          so you do not need to remove and re-submit your {PRODUCT_NAME.toLowerCase()}.
        </Text>
      </div>
      <div className={css.errorAlert}>
        {props.errors &&
          <Alert variant='error'>{formatGraphQLError(props.errors)}</Alert>
        }
      </div>
    </Modal>
  )
}
SubmitToFeedModal.propTypes = {
  buttons: PropTypes.node,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  errors: PropTypes.object
}

export default SubmitToFeedModal
