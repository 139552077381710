import css from './StarsCell.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'
// import DevicesContext from '../DevicesContext'

const StarsCell = ({ stars, avgRating }) => {
  // const { mobile } = useContext(DevicesContext)
  return (
    <div className={css.container}>
      <div className={css.stars} aria-hidden="true">
        {stars.length && stars.map((star, key) => (
          <div className={css.star} title={star} key={key}></div>
        ))}
      </div>
      {avgRating &&
        <Text variant="standard" className={css.value}>{avgRating} </Text>
      }
    </div>
  )
}

StarsCell.propTypes = {
  avgRating: PropTypes.string,
  stars: PropTypes.array
}

export default StarsCell
