import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'

import { FormContextProvider } from './FormContext'

import { getBounds } from '../../helpers/ui'

// making sure accepted props won't get cleared by some optimizer
const PROP_TYPES = {
  gtm: PropTypes.string,
  innerRef: PropTypes.object,
  fake: PropTypes.bool,
  onValid: PropTypes.func,
  onInvalid: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.node
}

class Form extends React.PureComponent {
  static propTypes = PROP_TYPES

  components = {}

  setValidity = (name, isValid, elRef, showError) => {
    if (!this.components[name]) {
      this.components[name] = {}
    }

    if (elRef) {
      this.components[name].elRef = elRef
      this.components[name].showError = showError
    }

    this.components[name].isValid = isValid

    this.checkFormValidity()
  }

  unsetValidity = (name) => {
    delete this.components[name]
    this.checkFormValidity()
  }

  checkFormValidity() {
    if (this.props.onInvalid || this.props.onValid) {
      for (const key in this.components) {
        const component = this.components[key]
        if (!component.isValid) {
          return this.props.onInvalid && this.props.onInvalid()
        }
      }

      this.props.onValid && this.props.onValid()
    }
  }

  handleSubmit = (event) => {
    const { onSubmit } = this.props

    for (const key in this.components) {
      const component = this.components[key]
      if (!component.isValid) {
        event && event.preventDefault()
        component.showError()
        const bounds = getBounds(component.elRef.current)
        window.scrollTo({ left: 0, top: bounds.top - window.innerHeight / 2, behavior: 'smooth' })
        return
      }
    }

    onSubmit && onSubmit(event)
  }

  render() {
    const { children, gtm, fake, innerRef } = this.props
    const formProps = omit(this.props, Object.keys(PROP_TYPES))

    const FormTag = fake ? 'div' : 'form'

    return (
      <FormContextProvider
        value={{
          setValidity: this.setValidity,
          unsetValidity: this.unsetValidity
        }}
      >
        <FormTag
          className={gtm && `gtm-form-${gtm}`}
          {...formProps}
          ref={innerRef}
          {...(fake
            ? {
              onKeyPress: (event) => {
                if (event.target.tagName.toLowerCase() !== 'textarea' && event.which === 13) {
                  event.preventDefault()
                  this.handleSubmit()
                }
              }
            }
            : {
              noValidate: true,
              onSubmit: this.handleSubmit
            }
          )}
        >
          {children}
        </FormTag>
      </FormContextProvider>
    )
  }
}

export { default as Checkbox } from './Checkbox'
export { default as Radio } from './Radio'
export { default as TextField } from './TextField'
export { default as EmailField } from './EmailField'
export { default as PasswordField } from './PasswordField'
export { default as WebsiteField } from './WebsiteField'
export { default as ZipCodeField } from './ZipCodeField'
export { default as LocationField } from './LocationField'
export { default as EditorField } from './EditorField'
export { default as FileField } from './FileField'
export { default as RatingField } from './RatingField'
export { default as TextArea } from './TextArea'
export { default as Dropdown } from './Dropdown'
export { default as YearDropdown } from './YearDropdown'
export { default as CustomerCategoryDropdown } from './CustomerCategoryDropdown'
export { default as IndustryDropdown } from './IndustryDropdown'
export { default as StageDropdown } from './StageDropdown'
export { default as FundingSourcesDropdown } from './FundingSourcesDropdown'
export { default as OrganizationSizeDropdown } from './OrganizationSizeDropdown'
export { default as InvestorTypeDropdown } from './InvestorTypeDropdown'
export { default as NumberOfInvestmentsDropdown } from './NumberOfInvestmentsDropdown'
export { default as TrackDropdown } from './TrackDropdown'
export { default as YesNoDropdown } from './YesNoDropdown'
export { default as RegionDropdown } from './RegionDropdown'
export { default as FormCol } from './FormCol'
export { default as FormRow } from './FormRow'
export { default as FormErrors } from './FormErrors'
export { default as FormSection } from './FormSection'
export { default as FormFooter } from './FormFooter'
export { Form }
