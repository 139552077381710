import css from './MenuDesktop.module.sass'

import React, { useContext, useEffect, useState } from 'react'
import { withRouter, matchPath } from 'react-router-dom'
import PropTypes from 'prop-types'


import ConnectionsBadge from '../../ConnectionsBadge'
import Icon from '../../Icon'
import Ionicon from '../../Ionicon'
import Link from '../../Link'

import CurrentUserContext from '../../CurrentUserContext'

import {
  PITCHTAPE_PATH, CONNECTIONS_PATH, SETTINGS_PATH, FEED_PATH, SIGNOUT_PATH,
  ACTIVITY_PATH, PROFILE_PATH, CREATE_PATH, ANALYTICS_PATH, FEED_LIST_PATH, SAVED_PATH,
  METRICS_PATH
} from '../../../constants/routes'
import {
  TOUR_INVESTOR_FEED_CLASS,
  TOUR_INVESTOR_ACTIVITY_CLASS,
  TOUR_INVESTOR_PROFILE_CLASS, TOUR_INVESTOR_FEED_LIST_CLASS
} from '../../../constants/tour'
import {
  CONNECTIONS_MODULE_ENABLED,
  PRODUCT_NAME,
  VIDEO_REVIEWER_PROFILE_ENABLED,
  SAVED_MODULE_ENABLED,
  METRICS_MODULE_ENABLED
} from "../../../constants/enterpriseConfig"

const MenuDesktop = ({ location }) => {
  const { currentUser: { firstName, accountType } } = useContext(CurrentUserContext)
  const inSavedList = !(matchPath(location.pathname, { path: SAVED_PATH }) === null)

  const [activePage, setActivePage] = useState('')

  useEffect(() => {
    if (location.pathname) {
      const page = location.pathname.split('/')[1]
      setActivePage(page)
    }
  }, [location])


  const renderInitialsDropdown = (accountPath, settingsPath) =>
    <li className={`${css.menuItemWithDropdown} ${TOUR_INVESTOR_PROFILE_CLASS}`}>
      <Link variant='mainnav' nav to={VIDEO_REVIEWER_PROFILE_ENABLED ? accountPath : '#'}>
        <span className={css.initials}>{firstName.slice(0, 1)}</span>
        <Ionicon name='arrowDropdown' size='24' color='deepBlue' />
      </Link>
      <ul className={css.menuDropdownRight}>
        <li className={css.menuDropdownItem}>
          <Link
            variant='mainnavSub'
            to={settingsPath}
            icon={<Ionicon name='settings' />}
          >

            Settings
          </Link>
        </li>

        {/* {accountType === 'founder' &&
          <li className={css.menuDropdownItem}>
            <Link
              variant='mainnavSub'
              to={BILLING_PATH}
              icon={<Ionicon name='card' />}
            >
              Plans & Billing
            </Link>
          </li>
        } */}

        <li className={css.menuDropdownItem}>
          <Link
            variant='mainnavSub'
            to={SIGNOUT_PATH}
            icon={<Ionicon name='power' />}
          >
            Log Out
          </Link>
        </li>
      </ul>
    </li>

  return (
    <>
      {accountType === 'founder' &&
        <ul className={css.menu}>
          <div className={css.containerItems}>
            <li className={css.menuItem}>
              <Link active={activePage === 'create'} variant="mainnav" to={CREATE_PATH} nav exact>  {PRODUCT_NAME} Profile</Link>
            </li>
            <li className={css.menuItem}>
              <Link active={activePage === 'pitch'} variant="mainnav" to={PITCHTAPE_PATH} nav exact>View {PRODUCT_NAME}</Link>
            </li>
          </div>
          <li className={css.menuItem}>
            {CONNECTIONS_MODULE_ENABLED &&
              <Link active={activePage === 'analytics'} variant="mainnav" nav to={ANALYTICS_PATH}>
                Analytics Dashboard
              </Link>
            }
          </li>

          <li className={css.menuItemConnections}>
            {CONNECTIONS_MODULE_ENABLED &&
              <Link
                active={activePage === 'connections'}
                variant="mainnav"
                nav
                to={CONNECTIONS_PATH}
                icon={<Icon name="envelope2" />}
              >
                Connections{' '}
                <span className={css.connectionsBadge}><ConnectionsBadge type={accountType} /></span>
              </Link>
            }
          </li>

          {renderInitialsDropdown(SETTINGS_PATH, SETTINGS_PATH)}
        </ul>
      }

      {accountType === 'investor' &&
        <ul className={css.menu}>
          <li className={`${css.menuItem} ${TOUR_INVESTOR_FEED_CLASS}`}>
            <Link active={activePage === 'feed'} variant="mainnav" nav exact to={FEED_PATH}>Feed</Link>
          </li>
          <li className={`${css.menuItem} ${TOUR_INVESTOR_FEED_LIST_CLASS}`}>
            <Link active={activePage === 'feedList'} variant="mainnav" nav exact to={FEED_LIST_PATH}>List</Link>
          </li>
          {SAVED_MODULE_ENABLED &&
            <li className={css.menuItem}>
              <Link
                variant="mainnav"
                nav
                to={SAVED_PATH}
                active={inSavedList}
                activeClassName={inSavedList ? css.NavActive : ''}
              >
                Favorites{' '}
              </Link>
            </li>
          }
          {CONNECTIONS_MODULE_ENABLED &&
            <li className={`${css.menuItem} ${TOUR_INVESTOR_ACTIVITY_CLASS}`}>
              <Link active={activePage === 'activity'} variant="mainnav" nav to={ACTIVITY_PATH}>
                Messages{' '}
                <span className={css.connectionsBadge}><ConnectionsBadge type={accountType} /></span>
              </Link>
            </li>
          }
          {METRICS_MODULE_ENABLED &&
            <li className={css.menuItem}>
              <Link
                variant="mainnav"
                nav
                to={METRICS_PATH}
                active={activePage === 'metrics'}
              >
                Metrics
              </Link>
            </li>
          }
          <li className={`${css.menuItemProfile} `}>
            {VIDEO_REVIEWER_PROFILE_ENABLED &&
              <Link active={activePage === 'profile'} variant="mainnav" nav to={PROFILE_PATH}>My Profile</Link>
            }
          </li>

          {renderInitialsDropdown(PROFILE_PATH, SETTINGS_PATH)}
        </ul>
      }
    </>
  )
}

MenuDesktop.propTypes = {
  location: PropTypes.object
}

export default withRouter(MenuDesktop)
