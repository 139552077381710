import css from './InfoCard.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Ionicon from './Ionicon'
import Text from './Text'
import Link from './Link'
import { INFO_COLOR } from '../constants/colors'
import { FEED_PATH } from '../constants/routes'


const InfoCard = ({ icon, title, color, value,
  isLink,
  text, width,
  height }) => {
  const infoColor = INFO_COLOR.find(el => el.name === color)

  return (
    <div className={css.container}>
      <div className={css.iconContainer}>
        <div className={css.circle} style={{ backgroundColor: infoColor.value }} />
        <div className={css.icon}>
          <Ionicon
            name={icon}
            width={width}
            height={height}
            stroke={icon}
            color={color}
          />
        </div>
      </div>
      <div className={css.content}>
        <Text className={css.title}> {title}</Text>
        <div className={css.totalContent}>
          <Text className={css.total}>{`${value}`}</Text>
          {
            !isLink ?
              <Text className={css.text} color={color}>{text}</Text>
              :
              <Link className={css.text} color={color} nav exact to={FEED_PATH}>{text}</Link>
          }

        </div>
      </div>
    </div>
  )
}

InfoCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.number,
  color: PropTypes.string,
  isLink: PropTypes.bool,
  text: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
}

export default InfoCard
