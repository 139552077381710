import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'

import Alert from '../Alert'
import Ionicon from '../Ionicon'
import Link from '../Link'

import { GET_COMPANY_INTERACTION } from '../../constants/queries'
import {
  GTM_INVESTOR_CLICKED_CONNECT_EVENT
} from '../../constants/gtm'
import { gtmTrack } from '../../helpers/tracking'
import { formatGraphQLError } from '../../helpers/errors'
import { CONNECTIONS_MODULE_ENABLED } from '../../constants/enterpriseConfig'

const CONNECT = gql`
  mutation connect ($company: String!) {
    connect(company: $company) {
      id
    }
  }
`

const updateInteraction = (cache, company, interaction) => {
  cache.writeQuery({
    query: GET_COMPANY_INTERACTION,
    variables: {
      id: company.id
    },
    data: {
      company: {
        ...company,
        interaction
      }
    }
  })
}

const InvestorActions = ({ company, onAction, onRedirectMessages }) => {

  const [connect, { error: connectError }] = useMutation(CONNECT, {
    onCompleted: () => {
      gtmTrack(GTM_INVESTOR_CLICKED_CONNECT_EVENT, {
        pitchtape: { id: company.id }
      })
      onAction && onAction('connected')
    },
    update: cache => updateInteraction(cache, company, 'connected'),
    onError: () => {
      onAction && onAction('connected')
    }
  })

  const error = connectError

  if (error) {
    return <Alert variant="error">{formatGraphQLError(error)}</Alert>
  }

  const { interaction } = company

  const connected = interaction === 'connected'
  if (!CONNECTIONS_MODULE_ENABLED) {
    return (<></>)
  }

  const onSendMessage = () => {
    if (!connected) {
      connect({ variables: { company: company.id } })
    } else {
      onRedirectMessages && onRedirectMessages()
    }
  }

  return (
    <Link
      color='white'
      variant='feedActionsMenu'
      icon={
        <Ionicon name='send_message_white' size='18' />
      }
      onClick={() => onSendMessage()}
    >
      <span>
        &nbsp;Send Message
      </span>
    </Link>
  )
}

InvestorActions.propTypes = {
  company: PropTypes.object,
  onAction: PropTypes.func,
  onRedirectMessages: PropTypes.func
}

export default InvestorActions
