import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Dropdown from './Dropdown'
// import Ionicon from '../Ionicon'

const GET_INDUSTRIES = gql`
  query getIndustries {
    industries: getIndustries {
      id
      name
    }
  }
`

const IndustryDropdown = ({ data, ...dropdownProps }) => {
  const options = useMemo(() => {
    return data.industries && data.industries.map(x => ({ value: x, label: x.name }))
  }, [data.industries])

  return (
    <Dropdown
      options={options}
      valueIdKey='id'
      // icon={<Ionicon name='industry' color='deepBlue' />}
      renderLabel={value => value.name}
      {...dropdownProps}
    />
  )
}

IndustryDropdown.propTypes = {
  data: PropTypes.object
}

export default graphql(GET_INDUSTRIES)(IndustryDropdown)
