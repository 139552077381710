import css from './Highlights.module.sass'
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import SectionHeading from './SectionHeading'

const Highlights = ({ company }) => {
  const articleRef = useRef(null)

  useEffect(() => {
    const { current: article } = articleRef

    Array
      .from(article.getElementsByTagName("a"))
      .forEach((anchor) => {
        anchor.target = "_blank"
      })
  }, [articleRef])

  return (
    <div className={css.container}>
      <section className={css.content}>
        <SectionHeading icon="highlights_black">
          Highlights
        </SectionHeading>

        <article
          ref={articleRef}
          dangerouslySetInnerHTML={{ __html: company.highlights }}
        />
      </section>
    </div>
  )
}

Highlights.propTypes = {
  company: PropTypes.object
}

export default Highlights
