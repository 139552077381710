import css from './Materials.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import DocViewer from '../DocViewer'

const Materials = ({ company, height }) => {
  const filesArray = ["technicalValidationStudyFile", "peerReviewResearchPapersFile",
    "caseStudiesFile", "otherMaterialsFile"]

  return (
    filesArray.map((item, idx) => {
      if (company[item]) {
        return (
          <div key={idx} className={css.container} style={{ height }}>
            <DocViewer
              variant={height ? 'fillHeight' : 'base'}
              src={company[item]}
              height={height}
            />
          </div>
        )
      } else {
        return null
      }
    })
  )
}

Materials.propTypes = {
  company: PropTypes.object,
  height: PropTypes.number
}

export default Materials
