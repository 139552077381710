import css from './AdditionalMaterials.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

import Alert from '../Alert'
import Button from '../Button'
import DocViewer from '../DocViewer'
import { FileField } from '../Form'
import Ionicon from '../Ionicon'
import SectionTitle from '../SectionTitle'
import Text from '../Text'

import { GET_USER_COMPANY_MAIN } from '../../constants/queries'
import { formatGraphQLError } from '../../helpers/errors'

const UPLOAD_OTHER_MATERIALS = gql`
  mutation($otherMaterialsFile: Upload) {
    uploadCompanyOtherMaterials(input: { otherMaterialsFile: $otherMaterialsFile }) {
      obj {
        otherMaterialsFile
      }
      errors {
        field
        messages
      }
    }
  }
`


const ACCEPTED_FILE_TYPES = ['.pdf', 'application/pdf'].join(',')

const AdditionalMaterials = ({ company }) => {
  const filesArray = [
    {
      description: "Upload any additional materials to support your submission. Combine multiple files into one PDF. File size is up to 10 MB.",
      mutation: UPLOAD_OTHER_MATERIALS,
      action: "uploadCompanyOtherMaterials",
      name: "otherMaterialsFile",
    }
  ]
  return (
    <section>
      <SectionTitle
        title='7. Additional Materials'
      />
      {filesArray.map((fileItem, idx) => {
        const action = fileItem.action
        const fileItemName = fileItem.name
        return (
          <div key={fileItem.name + 'container' + idx}>
            <SectionTitle
              key={fileItem.name + idx}
              description={<>
                <Text italic>{fileItem.description}</Text>
              </>
              }
            />
            <Mutation
              key={fileItem.mutation + idx}
              mutation={fileItem.mutation}
              update={(cache, { data: { [action]: { obj, errors } } }) => {
                if (errors) {
                  return
                }

                const { company } = cache.readQuery({ query: GET_USER_COMPANY_MAIN })

                cache.writeQuery({
                  query: GET_USER_COMPANY_MAIN,
                  data: {
                    company: {
                      ...company,
                      [fileItemName]: obj[fileItemName]
                    }
                  }
                })
              }}
            >
              {(action, { data, loading, error }) =>

                <>
                  {data && data.errors &&
                    <Alert variant='error'>{data.errors[0].messages.join('')}</Alert>
                  }

                  {error &&
                    <Alert variant='error'>{formatGraphQLError(error)}</Alert>
                  }

                  {company[fileItemName]
                    ? (
                      <>
                        <Button
                          variant='primary'
                          disabled={loading}
                          icon={<Ionicon name='trash' size='24' color='white' />}
                          onClick={() => {
                            action({
                              variables: {
                                [fileItemName]: null
                              }
                            })
                          }}
                        >
                          Delete file
                        </Button>

                        <div className={css.viewer}>
                          <DocViewer src={company[fileItemName]} />
                        </div>
                      </>
                    )
                    : (
                      <>
                        <FileField
                          accept={ACCEPTED_FILE_TYPES}
                          onChange={(file) => {
                            action({
                              variables: {
                                [fileItemName]: file
                              }
                            })
                          }}
                        >
                          {({ chooseFile }) =>
                            <div className={css.uploadButton}>
                              <Button
                                disabled={loading}
                                variant='primary'
                                icon={<Ionicon name='cloudUpload' color='white' size='32' />}
                                onClick={chooseFile}
                              >
                                Upload file
                              </Button>
                            </div>
                          }
                        </FileField>
                      </>
                    )
                  }
                </>
              }
            </Mutation>
          </div>)
      }
      )}
    </section>
  )
}

AdditionalMaterials.propTypes = {
  company: PropTypes.object
}

export default AdditionalMaterials
