import PropTypes from "prop-types"
import React from "react"

import Ionicon from "../Ionicon"
import { formatLocations } from "../../helpers/strings"
import { formatProperty } from "../PitchtapeReview/CompanyDetails"
import cssFeed from "../../screens/FeedListScreen.module.sass"
import Link from "../Link"
import { toWithParams } from "../../helpers/routes"
import { FEED_PATH } from "../../constants/routes"
import Tooltip from "../Tooltip"

const FeedItem = ({ index, company }) => {
    const videoId = index + 1
    const companyLocation = formatLocations(company.locations)

    const calculateGivenRate = () => {
        if (company.givenAvgRating === null) {
            return 0.0
        }
        else {
            return (company.givenAvgRating).toFixed(2)
        }
    }
    const renderRated = () => {
        if (company.givenAvgRating) {
            return (<Tooltip hoverable content={'yes'} ><Ionicon name='checkmark' color='green' size='24' /></Tooltip>)
        } else {
            return (<Tooltip hoverable content={'no'}><Ionicon name='close' color='nba' size='24' /></Tooltip>)
        }
    }
    const iconColor = 'blue'

    return (
        <tr className={index % 2 ? cssFeed.odd : cssFeed.even}>
            <td>
                {videoId}
            </td>
            <td>
                {company.name}
            </td>
            <td>
                {company.description}
            </td>
            <td>
                {Boolean(company.locations.length) &&
                    <>
                        <Ionicon name='location_black' color={iconColor} offset='right-5' style={{ verticalAlign: -3 }} />
                        {companyLocation}
                    </>
                }
            </td>
            <td>
                <Ionicon name='industry' color={iconColor} offset='right-5' style={{ verticalAlign: -3 }} />
                {formatProperty(company.industries)}
            </td>
            <td>
                <Ionicon name='customer_category_black' color={iconColor} offset='right-5' style={{ verticalAlign: -3 }} />
                {formatProperty(company.customerCategory)}
            </td>
            <td className={cssFeed.stage}><Ionicon name='stage' color={iconColor} offset='right-5'
                style={{ verticalAlign: -2 }} />
                {formatProperty(company.stage)}
            </td>
            <td>
                {formatProperty(company.applicationTrack)}
            </td>
            <td className={cssFeed.buttonAction}>
                <Link to={toWithParams(FEED_PATH, { videoId: videoId })}>
                    <Ionicon name='pitchtapeIcon' size='24' />
                </Link>
            </td>
            <td className={cssFeed.buttonAction}>
                {renderRated()}
            </td>
            <td>
                {calculateGivenRate()}
            </td>
            <td>
                {formatProperty(company.givenRatingText)}
            </td>
            <td>
                <div
                    dangerouslySetInnerHTML={{
                        __html: company.givenNote
                    }}
                />
            </td>
        </tr>
    )
}

FeedItem.propTypes = {
    index: PropTypes.number,
    company: PropTypes.object
}

export default FeedItem