import React, { useState } from "react"
import css from './index.module.sass'
import { useQuery } from "react-apollo"

import { GET_RATED_COMPANIES, GET_INVESTOR_METRICS } from "../../constants/queries"
import Loader from "../Loader"
import CompanyMetrics from './CompanyMetrics'
import Layout from "../Layout"
import Alert from "../Alert"
import { formatGraphQLError } from "../../helpers/errors"
import Button from "../Button"
import Text from '../../components/Text'
import Ionicon from '../../components/Ionicon'
import Filter from "../../components/Feed/Filter"
import Modal from '../../components/Modal'
import InfoCard from '../InfoCard'
import InfiniteScroll from 'react-infinite-scroll-component'

const Metrics = () => {
  const [cursor, setCursor] = useState(null)
  const [data, setData] = useState([])
  const [infoData, setInfoData] = useState([])
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [stage, setStage] = useState(JSON.parse(localStorage.getItem('feedStage')) || null)
  const [stageData, setStageData] = useState(JSON.parse(localStorage.getItem('feedStage')) || null)
  const [industriesList, setIndustriesList] = useState(JSON.parse(localStorage.getItem('feedIndustries')) || [])
  const [industries, setIndustries] = useState(JSON.parse(localStorage.getItem('feedIndustries')) || [])



  const { data: savedData, loading, error, networkStatus, refetch } = useQuery(GET_RATED_COMPANIES, {
    onCompleted: (responseData) => {
      setData(data.concat(responseData?.getRatedCompanies?.edges))
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 20,
      after: cursor,
      industries: industriesList,
      stage: stageData ? stageData.id : "",
      customerCategory: "",
    }
  })

  const { data: saveInfoData } = useQuery(GET_INVESTOR_METRICS, {
    onCompleted: () => {
      setInfoData(saveInfoData?.getInvestorMetrics)
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  })

  const overallPercentage = (infoData?.totalRatingsSubmitted * 100 / infoData?.totalCompaniesAssigned).toFixed(0)
  const favoritePercentage = (infoData?.totalFavoritePitches * 100 / infoData?.totalCompaniesAssigned).toFixed(0)
  const avgRatingStar = infoData?.avgRatingScore !== 5

  const infoMetrics = [
    {
      name: 'pitches',
      value: Number(infoData?.totalCompaniesAssigned),
      title: 'Total pitches assigned',
      isLink: true,
      text: 'Let’s review',
      width: '18',
      height: '18'
    },
    {
      name: 'ratings',
      value: Number(infoData?.totalRatingsSubmitted),
      title: 'Total ratings submitted',
      isLink: false,
      text: `${overallPercentage}% Completed`,
      width: '20.04',
      height: '17'
    },
    {
      name: 'avgRatings',
      value: Number(infoData?.avgRatingScore?.toFixed(2)),
      title: 'Avg rating score',
      isLink: false,
      text: avgRatingStar ? 'Out of 5 stars' : '5 stars',
      width: '20',
      height: '20'
    },
    {
      name: 'favorites',
      value: Number(infoData?.totalFavoritePitches),
      title: 'Total favorite pitches',
      isLink: false,
      text: `${favoritePercentage}% of total assigned`,
      width: '19',
      height: '17'
    }
  ]

  const fetchMoreListItems = () => {
    setCursor(savedData?.getRatedCompanies?.pageInfo?.endCursor)
  }

  const handleSaveFilter = () => {
    setIndustriesList(industries)
    setStageData(stage)
    setOpenFilterModal(false)
    clearForQuery()
  }

  const handleCancelFilter = () => {
    setIndustries(industries.slice(0, industriesList.length))
    setStage(stageData)
    setOpenFilterModal(false)
  }

  const handleClearFilter = () => {
    setIndustriesList([])
    setStageData(null)
    setIndustries([])
    setStage(null)
    clearForQuery()
  }

  const clearForQuery = () => {
    setData([])
    setCursor(null)
    refetch()
  }

  const renderCards = () => {
    return (
      <div className={css.containerInfoCard}>
        {
          infoMetrics.map((el, index) => {
            return (
              <InfoCard
                key={index + 1}
                color={el.name}
                title={el.title}
                icon={el.name}
                value={el.value}
                isLink={el.isLink}
                text={el.text}
                width={el.width}
                height={el.height}
              />
            )
          })
        }
      </div>
    )
  }

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMoreListItems}
      hasMore={savedData?.getRatedCompanies?.pageInfo?.hasNextPage}
    >
      <div className={css.layout}>
        {error &&
          <Layout variant='dashboard'>
            <Alert variant='error'>{formatGraphQLError(error)}</Alert>
          </Layout>
        }
        <div className={css.containerHeader}>
          <div className={css.containerTitle}>
            {<Text className={css.titleLayout}>Metrics Overview</Text>}
            {<Text className={css.subTitleLayout}>Overview of your assigned pitches, ratings and reviews</Text>}
          </div>
        </div>
        {renderCards()}
        <div className={css.containerTable}>
          {<Text className={css.titleTable}>Top rated pitches</Text>}
          <table className={css.dataTable} cellSpacing="0">
            <thead>
              <tr>
                <th>PITCH NAME</th>
                <th>DESCRIPTION</th>
                <th>LOCATION</th>
                <th>INDUSTRY</th>
                <th>CUSTOMER TYPE</th>
                <th className={css.stage}>STAGE</th>
                <th>TRACK</th>
                <th>YOUR RATING</th>
                <th>COMMENTS</th>
                <th>NOTES</th>
              </tr>
            </thead>
            <tbody>
              {
                data &&
                data.map((company, index) => {
                  const companyData = company.node
                  return (
                    <CompanyMetrics
                      key={index}
                      index={index}
                      connectionId={companyData.company.id}
                      company={companyData}
                    />
                  )
                })
              }
            </tbody>
          </table>
        </div>
        {(loading || networkStatus === 4) &&
          <div className={css.loading}>
            <Loader variant='centered' />
          </div>
        }

        {openFilterModal &&
          <Modal
            variant="filter"
            visible
            noButtons
            fullFooter
            title={<>

              <Text tag="h1" variant="h2" color="white">Filters</Text>
              <Button variant="icon" className={css.btnClose} onClick={() => handleCancelFilter()}>
                <Ionicon name="close" size="32" color="white" />
              </Button>
            </>
            }
            content={
              <Filter
                industries={industries}
                setIndustries={setIndustries}
                stage={stage}
                setStage={setStage}
                customerCategory={null}
                setCustomerCategory={null}
                onSaveFilter={handleSaveFilter}
                onCancelFilter={handleCancelFilter}
                onClearFilter={handleClearFilter}
              />
            }
          />

        }
      </div>
    </InfiniteScroll>
  )
}

Metrics.propTypes = {
}

export default Metrics
