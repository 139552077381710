import PropTypes from "prop-types"
import React from "react"

import { formatProperty } from "../PitchtapeReview/CompanyDetails"

import cssFeed from "../../components/Metrics/index.module.sass"
import StarsCell from "../StarsCell/StarsCell"

const CompanyMetrics = ({ index, company }) => {
  const companyData = company.company

  const calculateGivenRate = () => {
    const avgRating = company.avgRating?.toFixed(1) || 0.0
    const maxAverage = 5
    let avgRatingCalc = avgRating
    let stars = []
    for (let i = 1; i <= maxAverage; i++) {
      if (avgRatingCalc <= 0) {
        stars.push(0)
      }
      if (avgRatingCalc >= 1) {
        stars.push(100)
      }
      if (avgRatingCalc < 1 && avgRatingCalc > 0) {
        stars.push((avgRatingCalc * 100).toFixed(0))
      }
      avgRatingCalc--

    }
    if (company.avgRating === null) {
      return avgRating
    }
    else {
      return (
        <StarsCell avgRating={avgRating} stars={stars} />
      )
    }
  }


  return (
    <tr className={index % 2 ? cssFeed.even : cssFeed.odd}>
      <td>
        {companyData.name}
      </td>
      <td>
        {companyData.description}
      </td>
      <td>
        {companyData.locations[0]?.administrativeDivision1.name}
      </td>
      <td>
        {formatProperty(companyData.industries)}
      </td>
      <td>
        {formatProperty(companyData.customerCategory)}
      </td>
      <td className={cssFeed.stage}>
        {formatProperty(companyData.stage)}
      </td>
      <td>
        {formatProperty(companyData.applicationTrack)}
      </td>
      <td>
        {calculateGivenRate()}
      </td>
      <td>

        {company.ratingText}
      </td>
      <td>
        <div
          dangerouslySetInnerHTML={{
            __html: company.note.note
          }}
        />
      </td>
    </tr>
  )
}

CompanyMetrics.propTypes = {
  index: PropTypes.number,
  company: PropTypes.object,
}

export default CompanyMetrics
