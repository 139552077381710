import css from './CompanyDetails.module.sass'

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Ionicon from '../Ionicon'
import {
  FormRow,
  FormCol,
  TextField,
  LocationField,
  WebsiteField,
  CustomerCategoryDropdown,
  IndustryDropdown,
  StageDropdown,
  TrackDropdown
} from '../Form'
import SectionTitle from '../SectionTitle'
import Text from '../Text'



const CompanyDetails = ({
  name, locations, description, customerCategory, industries, stage,
  onUpdate, onInvalid, websiteUrl, applicationTrack, socialHandles, clients, investors,
  currentValuation, dateLatestFunding, totalFundingRaisedToDate
}) => {
  const [collapsed, setCollapsed] = useState(localStorage.getItem('companyDetailsCollapsed') === 'true')
  const [socialHandlesValue, setSocialHandlesValue] = useState({ value: '' })
  const [clientsValue, setClientsValue] = useState({ value: '' })
  const [investorsValue, setInvestorsValue] = useState({ value: '' })

  const handleCollapseClick = () => {
    setCollapsed(!collapsed)
    localStorage.setItem('companyDetailsCollapsed', !collapsed)
  }

  useEffect(() => {
    if (Array.isArray(socialHandles?.value)) {
      setSocialHandlesValue({
        ...socialHandles, value: socialHandles.value.join(',')
      })
    } else {
      setSocialHandlesValue(socialHandles)
    }
  }, [socialHandles])

  useEffect(() => {
    if (Array.isArray(clients?.value)) {
      setClientsValue({
        ...clients, value: clients.value.join(',')
      })
    } else {
      setClientsValue(clients)
    }
  }, [clients])

  useEffect(() => {
    if (Array.isArray(investors?.value)) {
      setInvestorsValue({
        ...investors, value: investors.value.join(',')
      })
    } else {
      setInvestorsValue(investors)
    }
  }, [investors])

  return (
    <section>
      <div className={collapsed ? css.btnCollapseActive : css.btnCollapse} onClick={handleCollapseClick}>
        <Button variant='icon'>
          <Ionicon name={collapsed ? 'arrowDropdown' : 'arrowDropup'} color='deepBlue' size='24' />
        </Button>
      </div>

      <SectionTitle
        required
        title='1. Basic Info'
      />
      <Text offset='double-bottom' italic>
        Enter information about your company. Please enter “N/A” if certain information is not
        applicable to your company. Information you enter will save automatically.
      </Text>

      {!collapsed &&
        <>
          <br />
          <br />
          <FormRow>
            <FormCol size='half'>
              <TextField
                label='Company Name'
                name='name'
                {...name}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>

          <FormRow offset='25'>
            <FormCol>
              <TextField
                label='Company Headline'
                name='description'
                {...description}
                placeholder='Describe your company in one sentence'
                maxlength={75}
                counter
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>

          <FormRow offset='25'>
            <FormCol>
              <LocationField
                label='Location'
                sublabel='Search and select city'
                name='locations'
                {...locations}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>

            <FormCol>
              <IndustryDropdown
                label='Industry'
                name='industries'
                {...industries}
                multiple
                maxlength={3}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>

          <FormRow offset='25'>
            <FormCol>
              <CustomerCategoryDropdown
                label='Customer Category'
                info={
                  'Consumer (or B2C) means that your primary customers are individuals. ' +
                  'Enterprise (or B2B) means that your primary customers are businesses.'
                }
                name='customerCategory'
                {...customerCategory}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>

            <FormCol>
              <WebsiteField
                label='Website URL'
                name='websiteUrl'
                {...websiteUrl}
                onUpdate={onUpdate}
              />
            </FormCol>
          </FormRow>
          <FormRow offset='25'>
            <FormCol>
              <TrackDropdown
                label='Application Track'
                name='applicationTrack'
                sublabel='Select one that is most relevant'
                {...applicationTrack}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>

          <FormRow offset='25'>
            <FormCol>
              <TextField
                label='Social Media'
                name='socialHandles'
                sublabel='Instagram, TikTok, Facebook, etc. Separate links with commas.'
                {...socialHandlesValue}
                maxlength={500}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>
          <FormRow offset='25'>
            <FormCol>
              <TextField
                label='Clients'
                name='clients'
                sublabel='List your notable clients, including sports leagues and teams, and how long you’ve been working with them'
                {...clientsValue}
                maxlength={500}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>
          <FormRow offset='25'>
            <FormCol>
              <TextField
                label='Investors'
                name='investors'
                sublabel='List your notable investors'
                {...investorsValue}
                maxlength={500}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>
          <FormRow offset='25'>
            <FormCol>
              <StageDropdown
                label='Stage'
                name='stage'
                {...stage}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
            <FormCol>
              <TextField
                label='Date of Latest Funding'
                name='dateLatestFunding'
                sublabel='MM/YYYY'
                {...dateLatestFunding}
                type='dateFormat'
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>
          <FormRow offset='25'>
            <FormCol>
              <TextField
                label='Valuation At Last Round Of Funding'
                name='currentValuation'
                sublabel='USD'
                {...currentValuation}
                type='money'
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
            <FormCol>
              <TextField
                label='Total Funding Raised To Date'
                name='totalFundingRaisedToDate'
                sublabel='USD'
                {...totalFundingRaisedToDate}
                type='money'
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>
        </>
      }
    </section>
  )
}

CompanyDetails.propTypes = {
  name: PropTypes.object,
  locations: PropTypes.object,
  description: PropTypes.object,
  customerCategory: PropTypes.object,
  industries: PropTypes.object,
  stage: PropTypes.object,
  onUpdate: PropTypes.func,
  onInvalid: PropTypes.func,
  websiteUrl: PropTypes.object,
  applicationTrack: PropTypes.object,
  socialHandles: PropTypes.object,
  clients: PropTypes.object,
  investors: PropTypes.object,
  currentValuation: PropTypes.object,
  dateLatestFunding: PropTypes.object,
  totalFundingRaisedToDate: PropTypes.object
}

export default CompanyDetails
