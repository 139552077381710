import PropTypes from "prop-types"
import React from "react"

import { formatLocations } from "../../helpers/strings"
import { formatProperty } from "../PitchtapeReview/CompanyDetails"

import cssFeed from "../../screens/FeedListScreen.module.sass"
import Link from "../Link"
import { toWithParams } from "../../helpers/routes"
import { FAVORITES_PATH } from "../../constants/routes"
import Ionicon from "../Ionicon"
import Moment from 'react-moment'
import DeleteSavedCompany from "../ConnectionList/DeleteSavedCompany"
import Tooltip from "../Tooltip"

export const renderRated = (company) => {
  if (company.givenRating !== -1) {
    return (<Tooltip hoverable content={'yes'} ><Ionicon name='checkmark' color='green' size='24' /></Tooltip>)
  } else {
    return (<Tooltip hoverable content={'no'}><Ionicon name='close' color='nba' size='24' /></Tooltip>)
  }
}

const CompanyItem = ({ index, connectionId, company, dateSaved, deleteCallBack }) => {

  const calculateGivenRate = () => {
    if (company.givenAvgRating === null) {
      return 0.0
    }
    else {
      return (company.givenAvgRating).toFixed(2)
    }
  }

  const renderRated = () => {
    if (company.givenAvgRating) {
      return (<Tooltip hoverable content={'yes'} ><Ionicon name='checkmark' color='green' size='24' /></Tooltip>)
    } else {
      return (<Tooltip hoverable content={'no'}><Ionicon name='close' color='nba' size='24' /></Tooltip>)
    }
  }

  const companyLocation = formatLocations(company.locations)
  const itemId = index + 1
  const iconColor = 'nba'
  return (
    <tr className={index % 2 ? cssFeed.odd : cssFeed.even}>
      <td>
        {itemId}
      </td>
      <td>
        {company.name}
      </td>
      <td>
        {company.description}
      </td>
      <td>
        {Boolean(company.locations.length) &&
          <>
            <Ionicon name='pin' offset='right-5' style={{ verticalAlign: -3 }} />
            {companyLocation}
          </>
        }
      </td>
      <td>
        <Ionicon name='industry' color='green' offset='right-5' style={{ verticalAlign: -3 }} />
        {formatProperty(company.industries)}
      </td>
      <td>
        <Ionicon name='customer_category_black' color='green' offset='right-5' style={{ verticalAlign: -3 }} />
        {formatProperty(company.customerCategory)}
      </td>
      <td className={cssFeed.stage}><Ionicon name='stage' color={iconColor} offset='right-5'
        style={{ verticalAlign: -2 }} />
        {formatProperty(company.stage)}
      </td>
      <td>
        {/* <Ionicon name='customer_category_black' color='green' offset='right-5' style={{ verticalAlign: -3 }} /> */}
        {formatProperty(company.applicationTrack)}
      </td>
      <td className={cssFeed.buttonAction}>
        <Link to={toWithParams(FAVORITES_PATH, { videoId: index })}>
          <Ionicon name='pitchtapeIcon' size='24' />
        </Link>
      </td>

      <td className={cssFeed.buttonAction}>
        {renderRated()}
      </td>
      <td>
        {calculateGivenRate()}
      </td>
      <td>
        {formatProperty(company.givenRatingText)}
      </td>
      <td>
        <div
          dangerouslySetInnerHTML={{
            __html: company.givenNote
          }}
        />
      </td>
      <td>
        {/* Using international standard for datetime format */}
        <Moment format="YYYY-MM-DD HH:mm:ss ">
          {dateSaved}
        </Moment>
      </td>
      <td>
        <DeleteSavedCompany connectionId={connectionId} deleteCallBack={deleteCallBack} />
      </td>
    </tr>
  )
}

CompanyItem.propTypes = {
  index: PropTypes.number,
  company: PropTypes.object,
  connectionId: PropTypes.string,
  dateSaved: PropTypes.string,
  deleteCallBack: PropTypes.func
}

export default CompanyItem
