export const PRODUCT_NAME = 'Pitch'
export const PORTAL_NAME = "AO Startups"
export const FEED_NAME = 'Pitch Videos'
export const PRODUCT_NAME_SHORT = 'Pitch'
export const URL = 'https://aostartups.ausopen.com'
export const LOGO = 'aoStartups/AO_Startups_RGB_Positive.svg'
export const CONTACT_EMAIL = 'hello@pitchtape.com'
export const VIDEO_CREATOR = 'Company'
export const VIDEO_CREATOR_PLURAL = 'Companies'
export const VIDEO_REVIEWER = 'Reviewer'
export const VIDEO_REVIEWER_PLURAL = 'Reviewers'
export const CONNECTIONS_MODULE_ENABLED = false
export const SAVED_MODULE_ENABLED = true
export const METRICS_MODULE_ENABLED = true
export const VIDEO_REVIEWER_TOUR_ENABLED = true
export const SIGNUP_REVIEWER_ENABLED = false
export const SHOW_EXAMPLES = false
export const LANDING_PAGE_ENABLED = false
export const VIDEO_REVIEWER_PROFILE_ENABLED = false
export const STRIPE_INTEGRATION_ENABLED = true
export const MAP_RATING_QUESTIONS = {
  rating1: '1. Team: The team has strong leadership with the necessary skill sets and experience to execute their immediate growth plans.',
  rating2: '2. Product: The product solves very real and large global problems that has application in the sports, health and entertainment industry.',
  rating3: '3. Advantage: The company has the factors that differentiate a company from its competition and make it uniquely great.',
  rating4: '4. Viability: The company is likely to achieve its immediate plans using the sought-after funding or strategic partnership with Tennis Australia.',
  rating5: '5. Proof of Concept: A PoC with a Tennis Australia property is recommended.'
}
export const REQUIRE_RECORD_ALL_TOPICS = true
