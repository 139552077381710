import React from 'react'

import Button from '../Button'
import Ionicon from '../Ionicon'
import Link from '../Link'
import SectionTitle from '../SectionTitle'
import Text from '../Text'

import { CREATE_TELEPROMPTER_SCRIPT } from '../../constants/routes'
import { VIDEO_PITCH_TEMPLATE_URL } from '../../constants/urls'
import { SHOW_EXAMPLES } from "../../constants/enterpriseConfig"

const PitchTemplates = () => {
  return (
    <section>
      <SectionTitle
        title='2. Teleprompter Script'
      />
      {
        <Text tag='p' italic offset='single-bottom' weight='400'>
          Prepare a script that turns into a video teleprompter.
        </Text>
      }
      <Button
        variant='primary'
        icon={<Ionicon name='list' color='white' size='24' />}
        to={CREATE_TELEPROMPTER_SCRIPT}
      >
        Prepare script
      </Button>

      {SHOW_EXAMPLES &&
        <Text tag="p" offset="single-top" variant="standardLarger">
          <Link variant="inherit" external href={VIDEO_PITCH_TEMPLATE_URL}>
            Video pitch script template doc
          </Link>
        </Text>
      }

    </section>
  )
}

export default PitchTemplates
