import css from './Text.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

let Text = ({
  tag, variant = 'standard', color, opacity, offset, weight, decoration, italic, block, highlight,
  linksInheritColor, truncated, centered, verticallyCentered, nowrap, uppercase, inline, className,
  lineHeight, children, ...rest
}) => {
  const TextTag = tag || 'span'

  return (
    <TextTag
      className={classNames(
        variant && css[variant],
        color && css['color-' + color],
        opacity && css['opacity-' + opacity],
        offset && css['offset-' + offset],
        weight && css['weight-' + weight],
        decoration && css['decoration-' + decoration],
        {
          [css.linksInheritColor]: linksInheritColor,
          [css.truncated]: truncated,
          [css.centered]: centered,
          [css.verticallyCentered]: verticallyCentered,
          [css.nowrap]: nowrap,
          [css.inline]: inline,
          [css.uppercase]: uppercase,
          [css.block]: block,
          [css.italic]: italic,
          [css.highlight]: highlight
        },
        className
      )}
      style={{
        lineHeight
      }}
      {...rest}
    >
      {children}
    </TextTag>
  )
}

Text.propTypes = {
  tag: PropTypes.string,
  variant: PropTypes.oneOf([
    null,
    'h1',
    'h2',
    'h3',
    'larger',
    'large',
    'button',
    'sectionTitle',
    'sectionTitleSmall',
    'blockTitle',
    'standardLarger',
    'standard',
    'light',
    'small',
    'smallUpper',
    'smallest',
    'icon'
  ]),
  color: PropTypes.oneOf([
    'black',
    'gray',
    'white',
    'blue',
    'red',
    'yellow',
    'green',
    'blue-bg',
    'yellow-bg',
    'green-bg',
    'pitches',
    'ratings',
    'avgRatings',
    'favorites'
  ]),
  opacity: PropTypes.oneOf([
    '50'
  ]),
  offset: PropTypes.oneOf([
    'quarter-top',
    'quarter-bottom',
    'half-top',
    'half-right',
    'half-bottom',
    'half-left',
    'single-top',
    'single-bottom',
    'double-top',
    'double-bottom'
  ]),
  weight: PropTypes.oneOf([
    '300',
    '400',
    '500',
    '700'
  ]),
  decoration: PropTypes.oneOf([
    'underline'
  ]),
  italic: PropTypes.bool,
  block: PropTypes.bool,
  highlight: PropTypes.bool,
  truncated: PropTypes.bool,
  centered: PropTypes.bool,
  verticallyCentered: PropTypes.bool,
  nowrap: PropTypes.bool,
  uppercase: PropTypes.bool,
  inline: PropTypes.bool,
  linksInheritColor: PropTypes.bool,
  className: PropTypes.string,
  lineHeight: PropTypes.string,
  children: PropTypes.node
}

export default Text
