import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import css from "./Filter.module.sass"
import TrackDropdown from '../Form/TrackDropdown'
import IndustryDropdown from "../Form/IndustryDropdown"
import StageDropdown from "../Form/StageDropdown"
import Text from "../Text";
import Button from '../Button'

const Filter = ({ industries, setIndustries, stage, setStage, track, setTrack, filterCallBack, onSaveFilter,
    onCancelFilter,
    onClearFilter, noCancel
}
) => {
    stage = stage === null ? [] : stage
    const handleUpdateTracs = (option) => {
        if (option) {
            setTrack(option.tracks)
            if (filterCallBack)
                filterCallBack()
        }
    }

    const onUpdateIndustries = (option) => {
        if (option) {
            setIndustries(option.industries)
            if (filterCallBack)
                filterCallBack()
        }
    }

    const handleUpdateStage = (option) => {
        if (option) {
            setStage(option.stages)
            if (filterCallBack)
                filterCallBack()
        }
    }
    useEffect(() => {
        localStorage.setItem('feedApplicationTrack', JSON.stringify(track))
    }, [track])

    useEffect(() => {
        localStorage.setItem('feedIndustries', JSON.stringify(industries))
    }, [industries])

    useEffect(() => {
        localStorage.setItem('feedStage', JSON.stringify(stage))
    }, [stage])

    return (
        <div className={css.containerModal}>
            <div className={css.dropDownFilter}>
                <div className={css.containerIcon}>
                    <Text variant='large' weight='500'>
                        &nbsp;Application Track
                    </Text>
                </div>
                <TrackDropdown
                    value={track}
                    name='tracks'
                    onUpdate={handleUpdateTracs}
                    multiple />
            </div>
            <div className={css.dropDownFilter}>
                <div className={css.containerIcon}>
                    <Text variant='large' weight='500'>
                        &nbsp;Industry
                    </Text>
                </div>
                <IndustryDropdown
                    value={industries}
                    name='industries'
                    onUpdate={onUpdateIndustries}
                    multiple />
            </div>
            <div className={css.dropDownFilter}>
                <div className={css.containerIcon}>
                    <Text variant='large' weight='500'>
                        &nbsp;Stage
                    </Text>
                </div>
                <StageDropdown
                    value={stage}
                    name='stages'
                    onUpdate={handleUpdateStage}
                    multiple
                />
            </div>


            <div className={css.conainerButtons}>
                <Button variant='tooltipWhite' decoration='underline' onClick={() => onClearFilter()}>
                    Clear all
                </Button>
                <div className={css.cancelSave}>
                    {!noCancel &&
                        <Button variant='outlineNarrow' onClick={() => onCancelFilter()}>
                            Cancel
                        </Button>
                    }
                    <Button variant='primaryNarrow' onClick={() => onSaveFilter()}>
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}

Filter.propTypes = {
    industries: PropTypes.array,
    setIndustries: PropTypes.func,
    stage: PropTypes.object,
    setStage: PropTypes.func,
    track: PropTypes.array,
    setTrack: PropTypes.func,
    filterCallBack: PropTypes.func,
    onSaveFilter: PropTypes.func,
    onCancelFilter: PropTypes.func,
    onClearFilter: PropTypes.func,
    noCancel: PropTypes.bool
}

export default Filter