import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloLink } from 'apollo-link'

import * as routes from './constants/routes'
import { INVALID_TOKEN_CODE, USER_UNAUTHORIZED_CODE, INVALID_CREDENTIALS_CODE } from './constants/codes'

const redirectTo = (path) => {
  document.dispatchEvent(new CustomEvent('redirect', { detail: path }))
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError && [401, 403].includes(networkError.statusCode)) {
    return redirectTo(routes.SIGNOUT_PATH)
  }

  if (graphQLErrors) {
    const gqlError = graphQLErrors[0]

    if (gqlError) {
      const { code } = gqlError

      if (code === INVALID_TOKEN_CODE || code === USER_UNAUTHORIZED_CODE) {
        return redirectTo(routes.SIGNOUT_PATH)
      } else if (code === INVALID_CREDENTIALS_CODE) {
        return
      }
    }

    graphQLErrors.map(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`)
  }
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `jwt ${token}` : ''
    }
  }
})

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    createUploadLink({
      uri: '/graphql/'
    })
  ]),
  cache: new InMemoryCache({
    freezeResults: true
  }),
  assumeImmutableResults: true
})

export default client
