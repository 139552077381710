import get from 'lodash.get'

export function addProtocol(url) {
  if (!url) {
    return url
  }

  return /:\/\//.test(url) ? url : `http://${url}`
}

export function removeProtocol(url) {
  return url.replace(/https?:\/\//, '')
}

const injectVars = (template, data) => {
  return template.replace(/\{{(.+)\}}/g, (m, p1) => get(data, p1))
}

export function formatMailto({ email, subject, body, signature, data }) {
  return (
    'mailto:' + email +
    '?subject=' + encodeURIComponent(injectVars(subject, data)) +
    '&body=' + (
      encodeURIComponent(injectVars(body, data)) +
      '%0D%0A%0D%0A---%0D%0A%0D%0A' +
      encodeURIComponent(injectVars(signature, data))
    )
  )
}

export function formatLocation({ name, country, administrativeDivision1 }) {
  return `${name}, ${country.code === 'US' ? `${administrativeDivision1.code}` : country.name}`
}

export function formatLocations(locations) {
  const location = locations[0]
  return location ? formatLocation(location) : ''
}

export function removeHtmlTags(html) {
  return html && html.replace(/(<\/?\w+>)|\s/g, '')
}

export function isUrl(text) {
  /* eslint-disable no-useless-escape */
  const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
  return text.match(urlRegex)
}
