import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import Layout from '../components/Layout'
import SignInForm from '../components/SignInForm'
import WhiteForm from '../components/WhiteForm'
import { Consumer as CurrentUserConsumer } from '../components/CurrentUserContext'

import { PITCHTAPE_PATH, FEED_PATH } from '../constants/routes'
import { INVALID_CREDENTIALS_CODE } from '../constants/codes'
import { PORTAL_NAME } from "../constants/enterpriseConfig"

const AUTH_USER = gql`
  mutation tokenAuth ($email: String!, $password: String!) {
    auth: tokenAuth(email: $email, password: $password) {
      token
    }
  }
`

const SignInScreen = ({ location }) =>
  <Layout variant='centered'>
    <WhiteForm
      h2={`Welcome to ${PORTAL_NAME}`}
      form={
        <CurrentUserConsumer>
          {({ currentUser, loading: currentUserLoading, onTokenChange }) =>
            <Mutation
              mutation={AUTH_USER}
              onCompleted={(data) => {
                onTokenChange(data.auth.token)
              }}
              onError={(error) => {
                if (error.graphQLErrors.length && error.graphQLErrors[0].code === INVALID_CREDENTIALS_CODE) {
                  return
                }

                throw error
              }}
            >
              {(tokenAuth, { error, loading }) => {
                if (currentUser.id) {
                  const from = location.state && location.state.from

                  return (
                    <Redirect
                      to={
                        from ||
                        (
                          currentUser.accountType === 'founder'
                            ? { pathname: PITCHTAPE_PATH, state: { signedIn: true } }
                            : FEED_PATH
                        )
                      }
                    />
                  )
                }

                return (
                  <SignInForm
                    loading={loading || currentUserLoading}
                    error={error}
                    onUserSignIn={(variables) => {
                      tokenAuth({ variables })
                    }}
                  />
                )
              }}
            </Mutation>
          }
        </CurrentUserConsumer>
      }
      footer={null}
    />
  </Layout>

SignInScreen.propTypes = {
  location: PropTypes.object
}

export default SignInScreen
