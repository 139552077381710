import { START_PLAN_NAME, GROW_PLAN_NAME, ACTIVE_STATUS } from '../constants/plans'
import { STRIPE_INTEGRATION_ENABLED } from '../constants/enterpriseConfig'

export const isSubscriptionActive = (subscription) =>
  subscription && subscription.status === ACTIVE_STATUS

export const isActiveGrowPlan = (subscription) =>
  subscription && subscription.plan === GROW_PLAN_NAME && subscription.status === ACTIVE_STATUS

export const isActivePaidPlan = (subscription) => {
  const isActivePaidPlan = subscription && subscription.plan !== START_PLAN_NAME && subscription.status === ACTIVE_STATUS
  return STRIPE_INTEGRATION_ENABLED ? true : isActivePaidPlan
}
