import gql from 'graphql-tag'

import {
  COMPANY_MEMBER_FRAGMENT,
  COMPANY_VIDEO_SEGMENT_FRAGMENT,
  USER_COMPANY_MAIN_FRAGMENT,
  USER_INVESTOR_PROFILE_FRAGMENT,
  LOCATION_FRAGMENT,
  CONNECTED_INVESTOR_FRAGMENT,
  CONNECTED_COMPANY_FRAGMENT,
  SAVED_COMPANY_FRAGMENT,
  CONCATENATED_VIDEO_FRAGMENT,
  COMPANY_FRAGMENT,
  COMPANY_TELEPROMPT_FRAGMENT, ERRORS_FOR_FIELDS_FRAGMENT
} from './fragments'

export const GET_USER_COMPANY_ID = gql`
  query getUserCompany {
    company: getUserCompany {
      id
    }
  }
`

export const GET_USER_COMPANY_MEMBERS = gql`
  query getUserCompany {
    company: getUserCompany {
      id
      members {
        ...companyMember
      }
    }
  }
  ${COMPANY_MEMBER_FRAGMENT}
`

export const GET_USER_COMPANY_DRAFT = gql`
  query getUserCompany {
    company: getUserCompany {
      id
      draft
    }
  }
`

export const GET_USER_COMPANY_MAIN = gql`
  query getUserCompany {
    company: getUserCompany {
      ...userCompanyMain,
    }
  }
  ${USER_COMPANY_MAIN_FRAGMENT}
`

export const GET_USER_COMPANY = gql`
  query getUserCompany {
    company: getUserCompany {
      ...userCompanyMain
      videoSegments {
        ...companyVideoSegment
      }
      concatenatedVideo {
        ...concatenatedVideo
      }
      teleprompts {
        ...companyTeleprompt
      }
      publishedAt
      applicationTrack {
			  id
				name
			}
      websiteUrl
      socialHandles
      clients
			investors
			currentValuation
			dateLatestFunding
			totalFundingRaisedToDate
      createdBy {
        email
        firstName
        lastName
      }
      startupUrl
    }
  }
  ${USER_COMPANY_MAIN_FRAGMENT}
  ${COMPANY_VIDEO_SEGMENT_FRAGMENT}
  ${CONCATENATED_VIDEO_FRAGMENT}
  ${COMPANY_TELEPROMPT_FRAGMENT}
`

export const GET_COMPANY_INTERACTION = gql`
  query getCompany ($id: String) {
    company: getCompany(id: $id) {
      id
      interaction
    }
  }
`

export const GET_COMPANY = gql`
  query getCompany ($id: String) {
    company: getCompany(id: $id) {
      ...company
      applicationTrack {
			  id
				name
			}
      websiteUrl
      socialHandles
      clients
			investors
			currentValuation
			dateLatestFunding
			totalFundingRaisedToDate
    }
  }
  ${COMPANY_FRAGMENT}
`

export const GET_ME = gql`
  query me {
    me {
      id
      firstName
      lastName
      email
      accountType
      emailConfirmed
      completedTourGuide
      skippedPracticeVideo
      isSuperuser
    }
  }
`

export const GET_USER_INVESTOR_PROFILE = gql`
  query getUserInvestorProfile {
    getUserInvestorProfile {
      ...userInvestorProfile
    }
  }
  ${USER_INVESTOR_PROFILE_FRAGMENT}
`

export const GET_CONNECTED_COMPANIES = gql`
  query getConnectedCompanies ($first: Int, $last: Int, $before: String, $after: String) {
    companies: getConnectedCompanies(first: $first, last: $last, before: $before, after: $after) @connection(key: "connectedCompanies") {
      totalUnreadMessages
      edges {
        node {
          ...connectedCompany
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CONNECTED_COMPANY_FRAGMENT}
`

export const GET_CONNECTED_COMPANY = gql`
  query getConnectedCompany ($id: Int) {
    getConnectedCompany(id: $id) {
      ...connectedCompany
    }
  }
  ${CONNECTED_COMPANY_FRAGMENT}
`

export const GET_SAVED_COMPANIES = gql`
  query getSavedCompanies ($first: Int, $last: Int, $before: String, $after: String) {
    companies: getSavedCompanies(first: $first, last: $last, before: $before, after: $after) {
      edges {
        node {
          id
          company {
            id
            name
            description
          }
          dateSaved
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const GET_SAVED_COMPANY = gql`
  query getSavedCompany ($id: Int) {
    getSavedCompany(id: $id) {
      ...savedCompany
    }
  }
  ${SAVED_COMPANY_FRAGMENT}
`

export const GET_CONNECTED_INVESTORS = gql`
  query getConnectedInvestors ($first: Int, $last: Int, $before: String, $after: String) {
    investors: getConnectedInvestors(first: $first, last: $last, before: $before, after: $after) @connection(key: "investors") {
      totalUnseen
      totalUnreadMessages
      edges {
        node {
          ...connectedInvestor
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
  ${CONNECTED_INVESTOR_FRAGMENT}
`

export const GET_CONNECTED_INVESTOR = gql`
  query getConnectedInvestor ($id: Int) {
    getConnectedInvestor(id: $id) {
      ...connectedInvestor
    }
  }
  ${CONNECTED_INVESTOR_FRAGMENT}
`
export const GET_FEED_LIST = gql`
  query getFeed ($first: Int, $last: Int, $before: String, $after: String, $offset: Int, $industries: [Int],
  $stages: [String], $customerCategories: [String], $q: String, $tracks: [Int]) {
    getFeed(first: $first, last: $last, before: $before, after: $after, offset: $offset, industries: $industries,
            stages: $stages, customerCategories: $customerCategories, q: $q, tracks: $tracks) {
      total
      edges {
        cursor
        node {
          id
          favorite
          interaction
          name
          applicationTrack {
		    	  id
			      name
		       }
          locations {
            ...location
          }
          website
          description
          givenAvgRating
          givenRatingText
          givenNote
          customerCategory {
            id
            name
          }
          industries {
            id
            name
          }
          stage {
            id
            name
          }
          createdBy {
            id
          }
        websiteUrl
				socialHandles
				clients
				investors
				currentValuation
				dateLatestFunding
				totalFundingRaisedToDate
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
  ${LOCATION_FRAGMENT}
`


export const GET_FEED = gql`
  query getFeed ($first: Int, $last: Int, $before: String, $after: String, $offset: Int, $industries: [Int],
  $stages: [String], $customerCategories: [String],$tracks: [Int], $q: String) {
    getFeed(first: $first, last: $last, before: $before, after: $after, offset: $offset, industries: $industries,
            stages: $stages, customerCategories: $customerCategories,tracks: $tracks, q: $q) {
      total
      edges {
        cursor
        node {
            applicationTrack {
              id
              name
            }
          id
          interaction
          givenAvgRating
          givenRatingText
          givenNote
          members {
            ...companyMember
          }
          name
          yearFounded
          locations {
            ...location
          }
          website
          description
          customerCategory {
            id
            name
          }
          industries {
            id
            name
          }
          stage {
            id
            name
          }
          regions {
            id
            name
          }
          createdBy {
            id
          }
          # revenue
          # activeUsers
          # previousCapital
          # fundingSources {
          #   id
          #   name
          # }
          # problem
          # solution
          # timing
          # marketPotential
          # businessModel
          # competitiveAdvantage
          slidesFile
          highlights
          videoSegments {
            ...companyVideoSegment
          }
          concatenatedVideo {
            ...concatenatedVideo
          }
          favorite
          websiteUrl
          socialHandles
          clients
          investors
          currentValuation
          dateLatestFunding
          totalFundingRaisedToDate
          peerReviewResearchPapersFile
          caseStudiesFile
          technicalValidationStudyFile
          otherMaterialsFile
        }
      }

      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${COMPANY_MEMBER_FRAGMENT}
  ${LOCATION_FRAGMENT}
  ${COMPANY_VIDEO_SEGMENT_FRAGMENT}
  ${CONCATENATED_VIDEO_FRAGMENT}
`

export const GET_COMPANIES = gql`
  query getCompanies ($first: Int, $last: Int, $before: String, $after: String, $offset: Int) {
    getCompanies(first: $first, last: $last, before: $before, after: $after, offset: $offset) {
      edges {
        node {
          id
          name
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      total
    }
  }
`

export const PUBLISH_COMPANY = gql`
  mutation publishCompany {
    publishCompany (input: {}) {
      errors {
        ...errorsForFields
      }
    }
  }
  ${ERRORS_FOR_FIELDS_FRAGMENT}
`

export const UNPUBLISH_COMPANY = gql`
  mutation unpublishCompany {
    unpublishCompany (input: {}) {
      errors {
        ...errorsForFields
      }
    }
  }
  ${ERRORS_FOR_FIELDS_FRAGMENT}
`

export const GET_FAVORITE_COMPANIES = gql`
query getFavoriteCompanies ($first: Int, $last: Int, $before: String, $after: String, $offset: Int,
  $industries: [Int], $customerCategories: [String], $stages: [String],$q: String , $tracks: [Int]
  ) {
  getFavoriteCompanies(first: $first, last: $last, before: $before, after: $after, offset: $offset,
    industries: $industries, customerCategories: $customerCategories, stages: $stages,q: $q ,tracks: $tracks
    ) {
    total   
    edges {
      node {
        company {
          applicationTrack {
            id
            name
          }
		  givenAvgRating
          givenRatingText
          givenNote
          id
          name
          description
          slidesFile
          highlights
          otherMaterialsFile
          peerReviewResearchPapersFile
          caseStudiesFile
          technicalValidationStudyFile
          websiteUrl
          socialHandles
          clients
          investors
          currentValuation
          dateLatestFunding
          totalFundingRaisedToDate
          locations {
            ...location
          }
          members {
            ...companyMember
          }
          customerCategory {
            id
            name
          }
          industries {
            id
            name
          }
          stage {
            id
            name
          }
          createdBy {
            id
          }
          videoSegments {
            ...companyVideoSegment
          }
          concatenatedVideo {
            ...concatenatedVideo
          }
        }
        dateFavorited

      }
      
      cursor
    }
      pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
}
${COMPANY_MEMBER_FRAGMENT}
${LOCATION_FRAGMENT}
${COMPANY_VIDEO_SEGMENT_FRAGMENT}
${CONCATENATED_VIDEO_FRAGMENT}
`
export const GET_RATED_COMPANIES = gql`
query getRatedCompanies($first: Int, $last: Int, $before: String, $after: String, $offset: Int) {
  getRatedCompanies(first: $first, last: $last, before: $before, after: $after, offset: $offset) {
    total
      edges {
        node {
        rating1
        rating2
        rating3
        rating4
        rating5
        ratingText
        avgRating
        company {
          id
          name
          description
          applicationTrack {
            id
            name
          }
						locations {
							...location
            __typename
          }
						industries {
            id
            name
          }
						customerCategory {
            id
            name
          }
						stage {
            id
            name
          }
        }
					note {
          id
          note
        }
      }
    }
      pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
    }
  }
}
${LOCATION_FRAGMENT}
`
export const GET_INVESTOR_METRICS = gql`
query {
    getInvestorMetrics {
    totalCompaniesAssigned
    totalRatingsSubmitted
    avgRatingScore
    totalFavoritePitches
  }
}
`