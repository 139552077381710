import css from './MoreActionFeed.module.sass'
import React from 'react'
import PropTypes from 'prop-types'

import InvestorActions from './InvestorActions'
import CompanyUrlCopier from '../CompanyUrlCopier'
import Link from '../Link'
import Ionicon from '../Ionicon'

const MoreActionFeed = ({ company, shareable, onAction, renderTakeNotes, onRedirectMessages }) => {

  return (
    <>
      <div id="more-action-feed" className={css.containerActions}>
        <InvestorActions company={company} onAction={onAction} onRedirectMessages={onRedirectMessages} />
        {shareable &&
          <CompanyUrlCopier company={company}>
            <Link
              variant='feedActionsMenu'
              color='white'
              icon={
                <Ionicon name='copy_link_white' size='18' />
              }
            >
              <span>
                &nbsp;Copy link
              </span>
            </Link>
          </CompanyUrlCopier>
        }
        <Link
          variant='feedActionsMenu'
          color='white'
          onClick={renderTakeNotes}
          icon={
            <Ionicon name='take_notes_white' size='18' />
          }
        >
          <span>
            &nbsp;Take notes
          </span>
        </Link>
      </div>

    </>
  )
}

MoreActionFeed.propTypes = {
  company: PropTypes.object,
  shareable: PropTypes.bool,
  onAction: PropTypes.func,
  renderTakeNotes: PropTypes.func,
  onRedirectMessages: PropTypes.func,
}

export default MoreActionFeed